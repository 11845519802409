<template>
    <div class="app-container">
        <el-card shadow="never">
            <div style="display: flex;justify-content:end;">
                <el-radio-group v-model="modeType" size="mini">
                    <el-radio-button label="列表模式" />
                    <el-radio-button label="树状模式" />
                </el-radio-group>
            </div>
            <el-empty style="padding-top: 20vh;" v-if="!isShow" :description="'没有组织结构'" />
            <vue3-tree-org v-show="modeType == '树状模式'" v-if="isShow" center style="height: calc(100vh - 170px);"
                :data="treeData" :horizontal="horizontal" :collapsable="collapsable" :label-style="style" :scalable="true"
                :default-expand-level="100" :props="treeProps" :define-menus="[]">
                <!-- 自定义节点内容 -->
                <template v-slot="{ node }">
                    <div class="tree-org-node__text node-label zdyedit">
                        <div style="font-size: 12px;" class="custom-content">{{ (node.$$data.orgType == 3 ? '市级' :
                            node.$$data.orgType == 4 ?
                                '区县' : node.$$data.orgType == 5 ? '学校' : '')
                        }}</div>
                        <div class="sss" v-if="node.$$data.orgType != 0">{{ node.label }}</div>
                        <div class="sss" v-if="node.$$data.orgType == 0">创建市级</div>

                        <el-avatar v-if="node.$$data.orgType != 0 && node.$$data.adminList.length != 0" :size="35"
                            :src="node.$$data.adminList[0].avatar + '?_=' + Date.now()" />
                        <div v-if="node.$$data.orgType != 0 && node.$$data.adminList.length != 0" style="font-size: 12px;"
                            class="custom-content">
                            {{ (node.$$data.adminList[0].trueName ? node.$$data.adminList[0].trueName : '') +
                                node.$$data.adminList[0].username }}
                        </div>
                        <div class="editbox">
                            <el-button v-has-btn="'1'" style="margin: 0 10px;font-size: 20px;"
                                v-if="node.$$data.orgType == 0" size="large" type="text" icon="el-icon-document-add"
                                title="创建市级" @click="handleCityOrgCreateOrg(node)">
                            </el-button>
                            <el-button v-has-btn="'1'" style="margin: 0 10px;font-size: 20px;"
                                v-if="node.$$data.orgType == 3" size="large" type="text" icon="el-icon-document-add"
                                title="创建区县" @click="handleAreaOrgCreateOrg(node)">
                            </el-button>
                            <el-button v-has-btn="'1'" style="margin: 0 10px;font-size: 20px;"
                                v-if="node.$$data.orgType != 5 && node.$$data.orgType != 0" size="large" type="text"
                                icon="el-icon-school" title="创建学校" @click="handleSchoolOrgCreateOrg(node)">
                            </el-button>
                            <el-button v-has-btn="'1,2'" v-if="node.$$data.orgType != 0"
                                style="margin: 0 10px;font-size: 20px;" size="large" type="text" icon="el-icon-edit"
                                title="编辑" @click="handleUpdate(node)">
                            </el-button>
                            <el-button v-has-btn="'3'" v-if="sfzs(node) && node.$$data.orgType != 0"
                                style="margin: 0 10px;font-size: 20px;" size="large" type="text" icon="el-icon-edit"
                                title="编辑" @click="handleUpdate(node)">
                            </el-button>
                            <el-popconfirm v-if="node.$$data.orgType != 3 && node.$$data.orgType != 0"
                                confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled" icon-color="#626AEF"
                                title="确定删除" @confirm="handleDelete(node)">
                                <template #reference>
                                    <el-button v-has-btn="'1'" style="margin: 0 10px;font-size: 20px;" size="large"
                                        title="删除" type="text" icon="el-icon-delete">
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </div>
                    </div>
                </template>
            </vue3-tree-org>
            <div v-show="modeType == '列表模式'" class="mt15">
                <div class="bre9e">
                    <el-table v-if="isShow" height="calc(100vh - 187px)" header-cell-class-name="bgf2" :data="tableData"
                        border :span-method="objectSpanMethod" style="width: 100%;">
                        <el-table-column prop="ptit" label="一级组织" width="150">
                            <template #default="scope">
                                <el-popover placement="bottom-start" :width="270" trigger="hover">
                                    <p>{{ scope.row.oneObj.orgName }}</p>
                                    <div style="display: flex;flex-wrap:wrap;">
                                        <div class="cxc" v-has-btn="'1'">
                                            <el-button style="margin: 0 0px;font-size: 20px;" size="large" type="text"
                                                icon="el-icon-document-add" title="创建市级" @click="handleCityOrgCreateOrg()">
                                            </el-button>
                                            <div class="fs-12">创建市级</div>
                                        </div>
                                        <div class="cxc" v-has-btn="'1'">
                                            <el-button style="margin: 0 0px;font-size: 20px;" size="large" type="text"
                                                icon="el-icon-document-add" title="创建区县"
                                                @click="handleAreaOrgCreateOrg(scope.row.oneObj)">
                                            </el-button>
                                            <div class="fs-12">创建区县</div>
                                        </div>
                                        <div class="cxc" v-has-btn="'1'">
                                            <el-button style="margin: 0 0px;font-size: 20px;" size="large" type="text"
                                                icon="el-icon-school" title="创建学校"
                                                @click="handleSchoolOrgCreateOrg(scope.row.oneObj)">
                                            </el-button>
                                            <div class="fs-12">创建学校</div>
                                        </div>
                                        <div class="cxc" v-has-btn="'1,2'">
                                            <el-button style="margin: 0 0px;font-size: 20px;" size="large" type="text"
                                                icon="el-icon-edit" title="编辑" @click="handleUpdate(scope.row.oneObj)">
                                            </el-button>
                                            <div class="fs-12">编辑</div>
                                        </div>
                                        <div class="cxc" v-has-btn="'3'" v-if="sfzs(scope.row.oneObj)">
                                            <el-button style="margin: 0 0px;font-size: 20px;" size="large" type="text"
                                                icon="el-icon-school" title="编辑" @click="handleUpdate(scope.row.oneObj)">
                                            </el-button>
                                            <div class="fs-12">编辑</div>
                                        </div>
                                    </div>
                                    <template #reference>
                                        <div style="color: #409efe;" class="text-one cursor"
                                            :title="scope.row.oneObj.orgName">
                                            {{ scope.row.oneObj.orgName }}
                                        </div>
                                    </template>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="组织性质" width="150">
                            <template #default="scope">
                                <div>
                                    {{ scope.row.oneObj.orgNatureName }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="服务日期" width="265">
                            <template #default="scope">
                                <div>
                                    {{ timestampToTime(scope.row.oneObj.beginTime) }} 至 {{
                                        timestampToTime(scope.row.oneObj.endTime) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ptit" label="二级组织名称" width="150">
                            <template #default="scope">
                                <div v-if="scope.row.twoObj">
                                    <el-popover placement="bottom-start" :width="270" trigger="hover">
                                        <p>{{ scope.row.twoObj.orgName }}</p>
                                        <div style="display: flex;flex-wrap:wrap;">
                                            <div class="cxc" v-if="scope.row.twoObj.orgType != 5" v-has-btn="'1'">
                                                <el-button style="margin: 0 0px;font-size: 20px;" size="large" type="text"
                                                    icon="el-icon-school" title="创建学校"
                                                    @click="handleSchoolOrgCreateOrg(scope.row.twoObj)">
                                                </el-button>
                                                <div class="fs-12">创建学校</div>
                                            </div>
                                            <div class="cxc" v-has-btn="'1,2'">
                                                <el-button style="margin: 0 0px;font-size: 20px;" size="large" type="text"
                                                    icon="el-icon-edit" title="编辑" @click="handleUpdate(scope.row.twoObj)">
                                                </el-button>
                                                <div class="fs-12">编辑</div>
                                            </div>
                                            <div class="cxc" v-has-btn="'3'" v-if="sfzs(scope.row.twoObj)">
                                                <el-button style="margin: 0 0px;font-size: 20px;" size="large" type="text"
                                                    icon="el-icon-school" title="编辑"
                                                    @click="handleUpdate(scope.row.twoObj)">
                                                </el-button>
                                                <div class="fs-12">编辑</div>
                                            </div>
                                            <el-popconfirm
                                                v-if="scope.row.twoObj.orgType != 3 && scope.row.twoObj.orgType != 0"
                                                confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                                icon-color="#626AEF" title="确定删除" @confirm="handleDelete(scope.row.twoObj)">
                                                <template #reference>
                                                    <div class="cxc" v-has-btn="'1'">
                                                        <el-button style="margin: 0 0px;font-size: 20px;" size="large"
                                                            title="删除" type="text" icon="el-icon-delete">
                                                        </el-button>
                                                        <div class="fs-12">删除</div>
                                                    </div>
                                                </template>
                                            </el-popconfirm>
                                        </div>
                                        <template #reference>
                                            <div style="color: #409efe;" class="text-one cursor"
                                                :title="scope.row.twoObj.orgName">
                                                {{ scope.row.twoObj.orgName }}
                                            </div>
                                        </template>
                                    </el-popover>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="组织性质" width="150">
                            <template #default="scope">
                                <div v-if="scope.row.twoObj">
                                    {{ scope.row.twoObj.orgNatureName }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="服务日期" width="265">
                            <template #default="scope">
                                <div v-if="scope.row.twoObj && scope.row.twoObj.beginTime">
                                    {{ timestampToTime(scope.row.twoObj.beginTime) }} 至 {{
                                        timestampToTime(scope.row.twoObj.endTime) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="ptit" label="三级组织名称" width="150">
                            <template #default="scope">
                                <div v-if="scope.row.threeObj">
                                    <el-popover placement="bottom-start" :width="270" trigger="hover">
                                        <p>{{ scope.row.threeObj.orgName }}</p>
                                        <div style="display: flex;flex-wrap:wrap;">
                                            <div class="cxc" v-has-btn="'1,2'">
                                                <el-button style="margin: 0 0px;font-size: 20px;" size="large" type="text"
                                                    icon="el-icon-edit" title="编辑"
                                                    @click="handleUpdate(scope.row.threeObj)">
                                                </el-button>
                                                <div class="fs-12">编辑</div>
                                            </div>
                                            <div class="cxc" v-has-btn="'3'" v-if="sfzs(scope.row.threeObj)">
                                                <el-button style="margin: 0 0px;font-size: 20px;" size="large" type="text"
                                                    icon="el-icon-school" title="编辑"
                                                    @click="handleUpdate(scope.row.threeObj)">
                                                </el-button>
                                                <div class="fs-12">编辑</div>
                                            </div>
                                            <el-popconfirm
                                                v-if="scope.row.threeObj.orgType != 3 && scope.row.threeObj.orgType != 0"
                                                confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                                icon-color="#626AEF" title="确定删除"
                                                @confirm="handleDelete(scope.row.threeObj)">
                                                <template #reference>
                                                    <div class="cxc" v-has-btn="'1'">
                                                        <el-button style="margin: 0 0px;font-size: 20px;" size="large"
                                                            title="删除" type="text" icon="el-icon-delete">
                                                        </el-button>
                                                        <div class="fs-12">删除</div>
                                                    </div>
                                                </template>
                                            </el-popconfirm>
                                        </div>
                                        <template #reference>
                                            <div style="color: #409efe;" class="text-one cursor"
                                                :title="scope.row.threeObj.orgName">
                                                {{ scope.row.threeObj.orgName }}
                                            </div>
                                        </template>
                                    </el-popover>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="组织性质" width="150">
                            <template #default="scope">
                                <div v-if="scope.row.threeObj">
                                    {{ scope.row.threeObj.orgNatureName }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="服务日期" width="265">
                            <template #default="scope">
                                <div v-if="scope.row.threeObj && scope.row.threeObj.beginTime">
                                    {{ timestampToTime(scope.row.threeObj.beginTime) }} 至 {{
                                        timestampToTime(scope.row.threeObj.endTime) }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </el-card>
        <el-dialog :title="'创建' + showTit" v-model="createKhCustomShow" width="60%" @close="restCreateKh">
            <el-form ref="createKhCustomForm" :model="createKhCustomForm" :rules="createKhCustomRules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-row :class="index != 0 ? mt15 : ''" v-for="(item, index) in createKhCustomForm.adminList">
                            <el-col :span="12">
                                <el-form-item :label="'管理员账号' + (index + 1)" prop="adminUsername">
                                    <div style="width: 100%;">
                                        <el-input @blur="validateUsername(item)" v-model="item.username"
                                            placeholder="最多16个字" maxlength="16" />
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4" v-if="createKhCustomForm.orgType != 3">
                                <el-icon @click="oncreateAddItem(index)" class="ml15 cursor" :size="30">
                                    <CirclePlus />
                                </el-icon>
                                <el-icon v-if="index != 0" @click="deletecreateRow(index)" class="ml15 cursor" :size="30">
                                    <Remove />
                                </el-icon>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="组织名称" prop="orgName">
                            <el-input v-model="createKhCustomForm.orgName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="服务日期" prop="serviceTime">
                        <el-col :span="11" class="date_picker">
                            <el-date-picker @change="startDateConfirm" style="width: 100%;"
                                v-model="createKhCustomForm.beginTime" type="date" placeholder="开始日期" value-format="x" />
                        </el-col>
                        <el-col :span="2" class="text-center">
                            <span class="text-gray-500">至</span>
                        </el-col>
                        <el-col :span="11">
                            <el-date-picker class="date_picker" @change="endDateConfirm" :disabled-date="enddisabledDate"
                                style="width: 100%;" v-model="createKhCustomForm.endTime" type="date" placeholder="结束日期"
                                value-format="x" />
                        </el-col>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="金牌助教" prop="isAide">
                            <el-radio-group v-model="createKhCustomForm.isAide">
                                <el-radio :label="1">开通</el-radio>
                                <el-radio :label="0">关闭</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="组织性质" prop="orgNature">
                            <el-select style="width: 100%;" v-model="createKhCustomForm.orgNature" placeholder="请选择组织性质">
                                <el-option v-for="item in orgNatureList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey">
                                    <span style="float: left">{{ item.dictValue }}</span>
                                    <span @click.stop="delFx(item)" style="float: right; color: #8492a6; font-size: 13px">
                                        <el-icon>
                                            <Delete />
                                        </el-icon>
                                    </span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-icon @click="openLeadClassify" class="ml15 cursor" :size="30">
                            <CirclePlus />
                        </el-icon>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitKhOrgForm">确 定</el-button>
                    <el-button @click="restCreateKh(); createKhCustomShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'创建'" v-model="createCustomShow" width="60%">
            <el-col>
                <el-form-item label="名称" prop="termId">
                    <el-input v-model="createdObj.orgName" placeholder="最多16个字" maxlength="16" />
                </el-form-item>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitzdyForm">确 定</el-button>
                    <el-button @click="createCustomShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'编辑' + showTit" v-model="customOrgUpdateShow" width="60%">
            <el-form ref="createKUpdateObj" :model="UpdateObj" :rules="createKhUpdateObjRules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-row :class="index != 0 ? mt15 : ''" v-for="(item, index) in UpdateObj.adminList">
                            <el-col :span="12">
                                <el-form-item :label="'管理员账号' + (index + 1)" prop="adminUsername">
                                    <div style="width: 100%;">
                                        <el-input @blur="validateUsername(item)" v-model="item.username"
                                            placeholder="最多16个字" maxlength="16" />
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="4" v-if="activeBcObj.pid != 0">
                                <el-icon @click="onAddItem(index)" class="ml15 cursor" :size="30">
                                    <CirclePlus />
                                </el-icon>
                                <el-icon v-if="index != 0" @click="deleteRow(index)" class="ml15 cursor" :size="30">
                                    <Remove />
                                </el-icon>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="组织名称" prop="orgName">
                            <el-input v-model="UpdateObj.orgName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item label="服务日期" prop="serviceCsTime">
                        <el-col :span="11" class="date_picker">
                            <el-date-picker @change="startupDateConfirm" style="width: 100%;"
                                v-model="UpdateObj.beginTime" type="date" placeholder="开始日期" value-format="x" />
                        </el-col>
                        <el-col :span="2" class="text-center">
                            <span class="text-gray-500">至</span>
                        </el-col>
                        <el-col :span="11">
                            <el-date-picker class="date_picker" @change="endupDateConfirm"
                                :disabled-date="endupdisabledDate" style="width: 100%;" v-model="UpdateObj.endTime"
                                type="date" placeholder="结束日期" value-format="x" />
                        </el-col>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="金牌助教" prop="isAide">
                            <el-radio-group v-model="UpdateObj.isAide">
                                <el-radio :label="1">开通</el-radio>
                                <el-radio :label="0">关闭</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="组织性质" prop="orgNature">
                            <el-select style="width: 100%;" v-model="UpdateObj.orgNature" placeholder="请选择组织性质">
                                <el-option v-for="item in orgNatureList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey">
                                    <span style="float: left">{{ item.dictValue }}</span>
                                    <span @click.stop="delFx(item)" style="float: right; color: #8492a6; font-size: 13px">
                                        <el-icon>
                                            <Delete />
                                        </el-icon>
                                    </span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-icon @click="openLeadClassify" class="ml15 cursor" :size="30">
                            <CirclePlus />
                        </el-icon>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitUpdateForm">确 定</el-button>
                    <el-button @click="customOrgUpdateShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'创建群组'" v-model="createQzShow" width="60%">
            <el-form ref="qzform" :model="qzform" :rules="createQzRules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群名称" prop="qunName">
                            <el-input v-model="qzform.qunName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群主账号" prop="adminAccount">
                            <el-input v-model="qzform.adminAccount" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="云本配置" prop="bookIds">
                            <el-col class="custom">
                                <el-link type="primary" @click="openQzBook" :underline="false">资源配置</el-link>
                                <div>
                                    <div class="fl-warp" style="display: flex;">
                                        <div v-for="item in qzbookList" class="csbbb"
                                            :style="qzform.bookIds.indexOf(item.id) != -1 ? '' : 'display:none'">
                                            <el-icon @click="delQzBookId(item.id)" class="eicon" style="cursor: pointer;">
                                                <Delete />
                                            </el-icon>
                                            <img style="width: 100px;margin-top: 10px;"
                                                :src="'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/book/' + item.id + '/img/cover.png_original'"
                                                alt="" class="image">
                                            <div style="width: 100px;text-align: center;" class="text-one mb15">{{
                                                item.bookName }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <el-checkbox-group v-model="qzform.bookIds">
                                    <el-checkbox :label="item.id" v-for="item in qzbookList">
                                        <img style="width: 100px;margin-top: 10px;"
                                            :src="'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/book/' + item.id + '/img/cover.png_original'"
                                            alt="" class="image">
                                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                                    </el-checkbox>
                                </el-checkbox-group> -->
                            </el-col>

                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitQzForm">确 定</el-button>
                    <el-button @click="configQzCancel">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'配置云本'" v-model="chooseBook" width="670px">
            <el-col class="custom ylsb" style="">
                <div v-for="item in bookList" :style="configObj.bookIds.indexOf(item.id) != -1 ? 'display:none' : ''">
                    <div class="ylqs" @click="setChooseBook(item.id)"
                        :style="chooseBookIds.indexOf(item.id) != -1 ? 'background: #a3d6ff' : ''">
                        <img style="width: 100px;margin-top: 10px;"
                            :src="'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/book/' + item.id + '/img/cover.png_original'"
                            alt="" class="image">
                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                    </div>
                </div>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitChooseBookForm">确 定</el-button>
                    <el-button @click="chooseBookCancel">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'配置云本'" v-model="chooseQzBook" width="670px">
            <el-col class="custom ylsb" style="">
                <div v-for="item in qzbookList" :style="qzform.bookIds.indexOf(item.id) != -1 ? 'display:none' : ''">
                    <div class="ylqs" @click="setChooseBook(item.id)"
                        :style="chooseBookIds.indexOf(item.id) != -1 ? 'background: #a3d6ff' : ''">
                        <img style="width: 100px;margin-top: 10px;"
                            :src="'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/book/' + item.id + '/img/cover.png_original'"
                            alt="" class="image">
                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                    </div>
                </div>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitQzChooseBookForm">确 定</el-button>
                    <el-button @click="chooseQzBookCancel">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'创建组织性质'" v-model="orgNatureShow" width="60%">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="名称" prop="orgName">
                        <el-input v-model="classifyName" placeholder="最多16个字" maxlength="16" />
                    </el-form-item>
                </el-col>
            </el-row>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submiTorgNatureShowForm">确 定</el-button>
                    <el-button @click="orgNatureShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessageBox } from 'element-plus'
import { queryUserInfo, queryDict, deleteDict } from "@/api/admin/public"
import { timestampToTime } from "@/utils/date";
import { orgListTree, orgCreate, orgUpdate, orgDelete, orgAddClassify } from "@/api/admin/system";
import { customOrgQueryTreeOrg, customOrgCreate, configCustom, customOrgBook, moveOrg } from "@/api/adminservice/custom";
import { qunQueryUser, qunCreate } from "@/api/adminservice/qun"


export default {
    name: "arrayMaintenance",
    components: {
    },
    data() {
        return {
            timestampToTime,
            showTit: '组织',
            modeType: '树状模式',
            chooseBook: false,
            chooseQzBook: false,
            showList: [],
            qzbookList: [],
            qzform: {
                bookIds: []
            },
            createQzRules: {
                qunName: [
                    { required: true, message: "请输入群名称", trigger: "blur" },
                ],
                adminAccount: [
                    { required: true, message: "请输入群主账号", trigger: "blur" },
                ],
                bookIds: [
                    { required: true, message: "请选择资源库书本", trigger: "change" },
                ],
            },
            createKhCustomForm: {
                adminUserId: '',
                adminUsername: '',
                orgName: '',
                orgType: 0,
                parentId: '',
                orgNature: '',
                beginTime: '',
                endTime: '',
                isAide:0,
                adminList: [{ username: '' }]
            },
            treeProps: {
                id: 'orgId',
                pid: 'parentId',
                label: 'orgName',
                children: 'childrenList'
            },
            treeData: {
                orgType: 0,
                label: 'XXX',
                childrenList: [],
                style: { "color": "#fff", "background": "#4c7796" },
            },
            horizontal: false,
            collapsable: true,
            cloneNodeDrag: false,
            expandAll: true,
            style: {
                background: "#fff",
                color: "#5e6d82",
            },
            customOrgUpdateShow: false,
            createCustomShow: false,
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                customId: '',
                orgId: '',
            },
            customList: [],
            orgList: [],
            // 表单参数
            form: {},
            // 表单校验
            createKhCustomRules: {
                adminUsername: [
                    // { required: true, message: "请输入管理员账号", trigger: "blur" },
                    // { validator: this.validateUsername, trigger: "blur" }
                    { required: true, validator: this.validateadminCreateAccount }
                ],
                orgName: [
                    { required: true, message: "请输入组织名称", trigger: "blur" }
                ],
                orgNature: [
                    { required: true, message: "请选择组织性质", trigger: "change" }
                ],
                serviceTime: [
                    { required: true, validator: this.checkValidator }
                ],
                isAide:[
                    { required: true, message: "请选择是否开通金牌助教", trigger: "change" }
                ]
            },
            createKhUpdateObjRules: {
                serviceCsTime: [
                    { required: true, validator: this.checkcsValidator }
                ],
                adminUsername: [
                    // { required: true, message: "请输入管理员账号", trigger: "blur" },
                    // { validator: this.validateUpdateUsername, trigger: "blur" }
                    { required: true, validator: this.validateadminAccount }

                ],
                orgName: [
                    { required: true, message: "请输入组织名称", trigger: "blur" }
                ],
                orgNature: [
                    { required: true, message: "请输入组织性质", trigger: "change" }
                ]
            },
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            createdObj: {
                orgName: '',
                parentOrgId: '',
                orgNature: '',
                orgType: 0      // /*客户及其下级分类：0-客户，1-客户类别，2-客户组织，3-组织类别，4-群组组织，5-群组*/
            },
            activeBcObj: {},
            UpdateObj: {
                adminList: [],
                adminUserId: '',
                adminUsername: '',
                orgId: '',
                orgName: '',
                orgNature: '',
                isAide:0,
            },
            isShow: false,
            createKhCustomShow: false,
            orgType: 0,
            configObj: {
                bookIds: [],
                orgId: ''
            },
            createQzShow: false,
            chooseBookIds: [],
            orgNatureShow: false,
            classifyName: '',
            orgNatureList: [],
            tableData: [],
        };
    },
    created() {
        this.treeData.label = JSON.parse(this.$storage.get("userInfo")).orgNatureName;
        this.getOrgListTree();
        this.getQueryDict();
    },
    methods: {
        objectSpanMethod(e) {
            // console.log(e);
            if (e.columnIndex === 0 || e.columnIndex === 1 || e.columnIndex === 2) {
                if (e.row.oneCol) {
                    // console.log(e,e.row.oneCol);
                    return {
                        rowspan: e.row.oneCol,
                        colspan: 1,
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            } else if (e.columnIndex === 3 || e.columnIndex === 4 || e.columnIndex === 5) {
                if (e.row.col != 0) {
                    return {
                        rowspan: e.row.col,
                        colspan: 1,
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }
        },
        onAddItem(i) {
            this.UpdateObj.adminList.splice((i + 1), 0, {
                username: ''
            })
        },
        oncreateAddItem(i) {
            this.createKhCustomForm.adminList.splice((i + 1), 0, {
                username: ''
            })
        },
        deleteRow(i) {
            this.UpdateObj.adminList.splice(i, 1)
        },
        deletecreateRow(i) {
            this.createKhCustomForm.adminList.splice(i, 1)
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.createKhCustomForm.beginTime === '' || !this.createKhCustomForm.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.createKhCustomForm.endTime === '' || !this.createKhCustomForm.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        startDateConfirm(e) {
            if (e >= this.createKhCustomForm.endTime) {
                this.createKhCustomForm.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e <= this.createKhCustomForm.beginTime) {
                this.createKhCustomForm.beginTime = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.createKhCustomForm.beginTime) {
                return current && current < this.createKhCustomForm.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.createKhCustomForm.beginTime === '' || !this.createKhCustomForm.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.createKhCustomForm.endTime === '' || !this.createKhCustomForm.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        checkcsValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.UpdateObj.beginTime === '' || !this.UpdateObj.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.UpdateObj.endTime === '' || !this.UpdateObj.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        startupDateConfirm(e) {
            if (e >= this.UpdateObj.endTime) {
                this.UpdateObj.endTime = '';
            }
        },
        endupDateConfirm(e) {
            console.log(e,)
            if (e <= this.UpdateObj.beginTime) {
                this.UpdateObj.beginTime = '';
            }
        },
        endupdisabledDate(current) {
            if (this.UpdateObj.beginTime) {
                return current && current < this.UpdateObj.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        openLeadClassify() {
            this.classifyName = ''
            this.orgNatureShow = true;
        },
        delFx(item) {
            var _this = this;
            ElMessageBox.confirm(
                '是否删除该组织性质?',
                '删除',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                deleteDict({ dicId: item.dicId }).then(res => {
                    if (res.data.code == 1) {
                        if (_this.createKhCustomForm.orgNature == item.dictKey) {
                            _this.createKhCustomForm.orgNature = ''
                        }
                        if (_this.UpdateObj.orgNature == item.dictKey) {
                            _this.UpdateObj.orgNature = ''
                        }
                        _this.$message.success('删除成功')
                        this.getQueryDict();
                    } else {
                        _this.$message.error('操作失败')
                    }
                })
            }).catch(() => {

            })
        },
        submiTorgNatureShowForm() {
            orgAddClassify({ classifyName: this.classifyName }).then(res => {
                if (res.data.code == 1) {
                    this.getQueryDict();
                    this.$message.success('添加成功')
                    this.orgNatureShow = false;
                } else {
                    this.$message.error('添加失败')
                }
            })
        },
        getQueryDict() {
            queryDict({ code: 'org_nature' }).then(res => {
                this.orgNatureList = res.data.data;
            })
        },
        delBookId(id) {
            this.configObj.bookIds.splice(this.configObj.bookIds.indexOf(id), 1)
        },
        setChooseBook(id) {
            if (this.chooseBookIds.indexOf(id) === -1) {
                this.chooseBookIds.push(id)
            } else {
                this.chooseBookIds.splice(this.chooseBookIds.indexOf(id), 1)
            }
        },
        chooseBookCancel() {
            this.chooseBook = false;
        },
        submitChooseBookForm() {
            this.configObj.bookIds = this.configObj.bookIds.concat(this.chooseBookIds)
            this.chooseBook = false;
        },
        openBook() {
            this.chooseBookIds = [];
            this.chooseBook = true;
        },
        openQzBook() {
            this.chooseBookIds = [];
            this.chooseQzBook = true;
        },
        delQzBookId(id) {
            this.qzform.bookIds.splice(this.qzform.bookIds.indexOf(id), 1)
        },
        chooseQzBookCancel() {
            this.chooseQzBook = false;
        },
        submitQzChooseBookForm() {
            this.qzform.bookIds = this.qzform.bookIds.concat(this.chooseBookIds)
            this.chooseQzBook = false;
        },
        editbj(row) {
            this.$router.push({
                path: "/groupSetAdd",
                query: {
                    customId: this.queryParams.customId,
                    groupData: JSON.stringify(row),
                    orgId: row.orgId
                }
            });
        },
        beforeDrag(e, f) {
            if (!f) {
                return false
            }
            console.log(f)
            console.log(e.$$data.orgId)
            console.log(this.findParentNode(e.$$data.orgId))
            if (e.$$data.orgType == 2) {
                if (this.findParentNode(f.$$data.orgId).indexOf(2) != -1) {
                    this.$message.error("组织不能移动到组织及组织以下结构")
                    return false
                } else {
                    moveOrg({ orgId: e.$$data.orgId, parentOrgId: f.$$data.orgId }).then(res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            this.$message.success("移动成功");
                            return true
                        } else {
                            console.log(434343)
                            this.$message.error("操作失败")
                            return false

                        }
                    })
                }
            } else {
                if (this.findParentNode(f.$$data.orgId).indexOf(2) == -1) {
                    this.$message.error("请选择有组织下的结构")
                    return false
                } else if (f.$$data.orgType == 5) {
                    this.$message.error("不能选择群组")
                    return false
                } else {
                    moveOrg({ orgId: e.$$data.orgId, parentOrgId: f.$$data.orgId }).then(res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            this.$message.success("移动成功");
                            return true
                        } else {
                            console.log(434343)
                            this.$message.error("操作失败")
                            this.getOrgListTree()
                            return false

                        }
                    })
                }
            }

        },
        validateadminCreateAccount(rule, value, callback) {
            var tg = 0;
            this.createKhCustomForm.adminList.forEach(e => {
                if (e.username) {
                    tg++;
                }
            })
            console.log(tg);
            if (tg == 0) {
                callback(new Error('请输入账号'));
            } else {
                callback();
            }
        },
        validateadminAccount(rule, value, callback) {
            var tg = 0;
            this.UpdateObj.adminList.forEach(e => {
                if (e.username) {
                    tg++;
                }
            })
            console.log(tg);
            if (tg == 0) {
                callback(new Error('请输入账号'));
            } else {
                callback();
            }
        },
        validateUsername(value) {
            if (value.username) {
                queryUserInfo({ mobile: value.username }).then(res => {
                    const result = res.data.data
                    if (!result) {
                        value.username = ''
                        this.$message.error("请输入正确账号")
                    } else {
                        value.userId = result.userId;
                    }
                })
            }

        },
        validateUpdateUsername(rule, value, callback) {
            queryUserInfo({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    this.UpdateObj.adminUserId = res.data.data.userId;
                    callback()
                }
            })
        },
        submitQzForm() {
            this.$refs["qzform"].validate(valid => {
                if (valid) {
                    console.log(this.qzform)
                    // if (this.customId) {
                    //     qunUpdate(this.form).then(res => {
                    //         if (res.data.code == 1) {
                    //             this.$message.success("编辑成功");
                    //             this.replace('/groupSet',{customId:this.customId})
                    //         } else {
                    //             this.$message.error("操作失败")
                    //         }
                    //     });
                    // } else {
                    qunCreate(this.qzform).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("新增成功");
                            this.getOrgListTree();
                            this.configQzCancel()
                            // this.$emit('isTg', true);
                        } else {
                            this.$message.error("操作失败")
                        }

                    });
                    // }

                }
            });
        },
        configQzCancel() {
            this.qzform = {}
            this.createQzShow = false;
        },
        submitConfigForm() {
            console.log(this.configObj)
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        screenZj(row) {
            // console.log(row)
            if (this.findParentNode(row.orgId).indexOf(2) == -1) {
                return false
            } else {
                return true
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.dataList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
        submitKhOrgForm() {
            console.log(this.activeBcObj)
            this.$refs["createKhCustomForm"].validate(valid => {
                if (valid) {
                    try {
                        console.log(1111);
                        var adminList = []
                        this.createKhCustomForm.adminList.forEach(e => {
                            if (e.userId) {
                                adminList.push(e.userId)
                            }
                        })
                        var obj = JSON.parse(JSON.stringify(this.createKhCustomForm))
                        obj.adminList = adminList;
                        if (this.activeBcObj) {
                            if (this.activeBcObj.$$data && this.activeBcObj.$$data.beginTime && this.activeBcObj.$$data.endTime) {
                                if (this.createKhCustomForm.beginTime < Number(this.activeBcObj.$$data.beginTime)) {
                                    this.$message.error('开始时间不得小于父级时间')
                                    return
                                }
                                if (this.createKhCustomForm.endTime > Number(this.activeBcObj.$$data.endTime)) {
                                    this.$message.error('结束时间不得大于父级时间')
                                    return
                                }
                            } else {
                                if (this.activeBcObj.beginTime && this.activeBcObj.endTime) {
                                    if (this.createKhCustomForm.beginTime < Number(this.activeBcObj.beginTime)) {
                                        this.$message.error('开始时间不得小于父级时间')
                                        return
                                    }
                                    if (this.createKhCustomForm.endTime > Number(this.activeBcObj.endTime)) {
                                        this.$message.error('结束时间不得大于父级时间')
                                        return
                                    }
                                }
                            }

                        }
                        orgCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("创建成功");
                                this.getOrgListTree();
                                this.createKhCustomShow = false;
                                this.restCreateKh();
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } catch (error) {
                        console.log(error);
                    }

                }
            });

        },
        restCreateKh() {
            this.createKhCustomForm = {
                adminUserId: '',
                adminUsername: '',
                orgName: '',
                orgType: 0,
                orgNature: '',
                parentId: '',
                beginTime: '',
                endTime: '',
                isAide:0,
                adminList: [{ username: '' }]
            }
        },
        sfzs(node) {
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            if (node.id) {
                var adList = this.findParentGlNode(node.id);
            } else {
                var adList = this.findParentGlNode(node.orgId);
            }
            adList.pop()
            var isTg = false;
            for (var i = 0; i < adList.length; i++) {
                var m = adList[i];
                if (this.toPdAdmin(m, userId)) {
                    isTg = true;
                }
            }
            return isTg
        },
        handleCityOrgCreateOrg(row) {
            this.activeBcObj = row;
            this.showTit = '市级'
            this.createKhCustomForm.orgType = 3;
            this.createKhCustomForm.parentId = 0;
            this.createKhCustomShow = true;
        },
        handleAreaOrgCreateOrg(row) {
            this.activeBcObj = row;
            this.showTit = '区县'
            this.createKhCustomForm.orgType = 4;
            if (row.$$data) {
                this.createKhCustomForm.parentId = row.$$data.orgId;
            } else {
                this.createKhCustomForm.parentId = row.orgId;
            }

            this.createKhCustomShow = true;
        },
        handleSchoolOrgCreateOrg(row) {
            this.activeBcObj = row;
            this.showTit = '学校'
            this.createKhCustomForm.orgType = 5;
            if (row.$$data) {
                this.createKhCustomForm.parentId = row.$$data.orgId;
            } else {
                this.createKhCustomForm.parentId = row.orgId;
            }
            this.createKhCustomShow = true;
        },
        submitUpdateForm() {
            console.log(this.activeBcObj.$$data)
            var timeList = this.findParentXzNode(this.activeBcObj.id);
            if (timeList.length > 1) {
                timeList.pop()
            }
            var ret = []
            timeList.forEach(e => {
                ret.push(e.split(','))
            })
            console.log(ret);
            if (this.activeBcObj)
                this.$refs["createKUpdateObj"].validate(valid => {
                    if (valid) {
                        // this.activeBcObj
                        var errDateList = []
                        if (this.activeBcObj.$$data.childrenList) {
                            this.activeBcObj.$$data.childrenList.forEach(e => {
                                if (e.beginTime && e.endTime) {
                                    console.log(e.beginTime, e.endTime);
                                    if (e.beginTime < this.UpdateObj.beginTime || e.endTime > this.UpdateObj.endTime) {
                                        errDateList.push(e)
                                    }
                                }
                                if (e.childrenList) {
                                    e.childrenList.forEach(j => {
                                        if (j.beginTime && j.endTime) {
                                            console.log(j.beginTime, j.endTime);
                                            if (j.beginTime < this.UpdateObj.beginTime || j.endTime > this.UpdateObj.endTime) {
                                                errDateList.push(j)
                                            }
                                        }
                                    })
                                }
                            })
                        }
                        if (errDateList.length != 0) {
                            console.log(errDateList);
                            var txtList = [];
                            errDateList.forEach(e => {
                                txtList.push(e.orgName)
                            })
                            var txt = txtList.toString('、') + '服务日期超过'+this.UpdateObj.orgName+'服务日期，请先更改组织服务日期'
                            console.log(txt);
                            this.$message.error(txt)
                            return
                        }
                        var adminList = []
                        this.UpdateObj.adminList.forEach(e => {
                            if (e.userId) {
                                adminList.push(e.userId)
                            }
                        })
                        var obj = JSON.parse(JSON.stringify(this.UpdateObj))
                        obj.adminList = adminList;
                        if (this.activeBcObj.pid == 0) {
                            orgUpdate(obj).then(res => {
                                if (res.data.code == 1) {
                                    this.$message.success("修改成功");
                                    this.getOrgListTree()
                                    this.customOrgUpdateShow = false;
                                } else {
                                    this.$message.error("操作失败");
                                }
                            })
                        } else {
                            var isTg = true;
                            for (var i = 0; i < ret.length; i++) {
                                var m = ret[i];
                                if (m[0]) {
                                    if (this.UpdateObj.beginTime < Number(m[0])) {
                                        isTg = false;
                                        this.$message.error('开始时间不得小于父级时间')
                                    }
                                    if (this.UpdateObj.endTime > Number(m[1])) {
                                        isTg = false;

                                        this.$message.error('结束时间不得大于父级时间')
                                    }
                                }
                            }
                            if (isTg) {
                                orgUpdate(obj).then(res => {
                                    if (res.data.code == 1) {
                                        this.$message.success("修改成功");
                                        this.getOrgListTree()
                                        this.customOrgUpdateShow = false;
                                    } else {
                                        this.$message.error("操作失败");
                                    }
                                })
                            }
                        }


                    }
                });
        },
        submitzdyForm() {
            customOrgCreate(this.createdObj).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("创建成功");
                    this.createCustomShow = false;
                    this.createdObj = {
                        orgName: '',
                        parentOrgId: '',
                        orgType: 0
                    }
                    if (this.queryParams.orgId) {
                        this.changeOrg()
                    } else {
                        this.getOrgListTree()
                    }

                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleCreateCustom(row, orgType) {
            this.createdObj.orgType = orgType;
            this.createdObj.parentOrgId = row.id;
            this.createdObj.orgName = '';
            console.log(row)
            // this.zdycustomName
            if (orgType == 5) {
                this.qzform.orgId = row.id
                this.qzform.bookIds = []
                customOrgBook({ orgId: row.$$data.orgId }).then(res => {
                    this.qzbookList = res.data.data;
                    // if(this.qzbookList.length == 0){
                    // }
                })
                this.createQzShow = true;
            } else {
                this.createCustomShow = true;
            }

        },
        changeOrg() {
            this.dataList = []
            this.treeData = {}
            if (this.queryParams.orgId) {
                customOrgQueryTreeOrg({ orgId: this.queryParams.orgId }).then(res => {
                    console.log(res)
                    this.dataList.push(res.data.data);
                    this.treeData.childrenList = [];
                    this.treeData.childrenList = res.data.data;
                    console.log(this.treeData)
                })
            }

        },
        // id 指的是当前点击的节点id;
        findParentXzNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.treeData)).childrenList;

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName,
                            beginTime: item.beginTime,
                            endTime: item.endTime,
                        };
                        parentIds = [item.beginTime + ',' + item.endTime];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.beginTime + ',' + a.endTime);
                        });
                        parentIds.push(item.beginTime + ',' + item.endTime);
                    }

                    // 结束遍历
                    parentIdsQueue = [];
                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName,
                            beginTime: item.beginTime,
                            endTime: item.endTime,
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            // console.log(parentIds)
            return parentIds
        },
        // id 指的是当前点击的节点id;
        findParentGlNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.showList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName,
                            adminList: item.adminList,
                        };
                        parentIds = [item.adminList];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            console.log(a);
                            parentIds.push(a.adminList);
                        });
                        parentIds.push(item.adminList);
                    }

                    // 结束遍历
                    parentIdsQueue = [];
                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName,
                            adminList: item.adminList,
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            // console.log(parentIds)
            return parentIds
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    console.log(e)
                    // if (e.orgType == 1 || e.orgType == 3) {

                    // }
                    if (e.orgType == 3) {
                        e.style = { "color": "#fff", "background": "#4c7796" }
                    }
                    if (e.orgType == 4) {
                        e.style = { "color": "#fff", "background": "#b4c9d9" }
                    }
                    if (e.orgType == 5) {
                        e.style = { "color": "#fff", "background": "#74b5a1" }
                    }
                    if (e.childrenList && e.childrenList.length != 0) {
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        getOrgListTree() {
            this.tableData = [];
            this.showList = [];
            orgListTree().then(res => {
                res.data.data.forEach(e => {
                    if (e.orgType == 3) {
                        e.style = { "color": "#fff", "background": "#4c7796" }
                    }
                    if (e.orgType == 4) {
                        e.style = { "color": "#fff", "background": "#b4c9d9" }
                    }
                    if (e.orgType == 5) {
                        e.style = { "color": "#fff", "background": "#74b5a1" }
                    }
                    if (e.childrenList && e.childrenList.length != 0) {
                        e.childrenList.forEach(j => {
                            if (j.orgType == 3) {
                                j.style = { "color": "#fff", "background": "#4c7796" }
                            }
                            if (j.orgType == 4) {
                                j.style = { "color": "#fff", "background": "#b4c9d9" }
                            }
                            if (j.orgType == 5) {
                                j.style = { "color": "#fff", "background": "#74b5a1" }
                            }
                            if (j.childrenList && j.childrenList.length != 0) {
                                j.childrenList.forEach(m => {
                                    if (m.orgType == 3) {
                                        m.style = { "color": "#fff", "background": "#4c7796" }
                                    }
                                    if (m.orgType == 4) {
                                        m.style = { "color": "#fff", "background": "#b4c9d9" }
                                    }
                                    if (m.orgType == 5) {
                                        m.style = { "color": "#fff", "background": "#74b5a1" }
                                    }
                                })
                            }
                        })
                    }
                })
                if (this.$storage.get("role") == 3) {
                    var userId = JSON.parse(this.$storage.get("userInfo")).userId
                    var hxData = JSON.parse(JSON.stringify(res.data.data))
                    console.log(userId);
                    hxData.forEach(e => {
                        // console.log(this.toPdAdmin(e.adminList, userId))
                        if (this.toPdAdmin(e.adminList, userId)) {
                            this.showList.push(e)
                        } else {
                            if (e.childrenList && e.childrenList.length != 0) {
                                for (var k = 0; k < e.childrenList.length; k++) {
                                    var j = e.childrenList[k];
                                    if (this.toPdAdmin(j.adminList, userId)) {
                                        // console.log('fddf', k);
                                    } else {
                                        res.data.data.forEach(e1 => {
                                            if (e1.childrenList && e1.childrenList.length != 0) {
                                                e1.childrenList.forEach(j1 => {
                                                    if (j1.orgId == j.orgId) {
                                                        if (j1.childrenList && j1.childrenList.length != 0) {
                                                            j1.childrenList.forEach(m1 => {
                                                                if (this.toPdAdmin(m1.adminList, userId)) {
                                                                    var zdy = []
                                                                    zdy.push(JSON.parse(JSON.stringify(e1)))
                                                                    zdy[0].childrenList = []
                                                                    zdy[0].childrenList.push(JSON.parse(JSON.stringify(j1)))
                                                                    zdy[0].childrenList[0].childrenList = [];
                                                                    zdy[0].childrenList[0].childrenList.push(JSON.parse(JSON.stringify(m1)))
                                                                    console.log('zdy', zdy);

                                                                    this.showList.push(zdy[0])
                                                                }
                                                            })
                                                        }
                                                    }
                                                })
                                            }
                                        })
                                        // console.log('dddd', k);
                                        e.childrenList.splice(k, 1)
                                        k--;
                                        console.log(e);
                                    }
                                }
                                if (e.childrenList.length != 0) {
                                    this.showList.push(e)
                                }

                            }
                        }
                    })
                }

                this.treeData.childrenList = [];
                if (this.$storage.get("role") == 3) {
                    var obj = {}
                    this.showList.forEach(e => {
                        if (obj[e.orgId]) {
                            obj[e.orgId] = [...obj[e.orgId], ...e.childrenList]
                        } else {
                            obj[e.orgId] = e.childrenList
                        }

                    })
                    this.showList = this.uniqueBy(this.showList, 'orgId')
                    this.showList.forEach(e => {
                        e.childrenList = obj[e.orgId]
                    })
                    console.log(obj);
                    console.log(this.showList);
                    this.treeData.childrenList = this.showList;
                } else {
                    this.treeData.childrenList = res.data.data;
                }

                // this.tableData = [];
                var obj = {}
                this.treeData.childrenList.forEach(e => {
                    if (e.childrenList && e.childrenList.length != 0) {
                        var jchillen = 0

                        e.childrenList.forEach(j => {
                            if (j.childrenList && j.childrenList.length != 0) {
                                jchillen = jchillen + j.childrenList.length

                                j.childrenList.forEach((m, n) => {
                                    if (n == 0) {
                                        this.tableData.push({ oneObj: e, twoObj: j, threeObj: m, col: j.childrenList.length })
                                    } else {
                                        this.tableData.push({ oneObj: e, twoObj: j, threeObj: m, col: 0 })
                                    }

                                })
                            } else {
                                jchillen = jchillen + 1
                                this.tableData.push({ oneObj: e, twoObj: j, threeObj: null, col: 1 })
                            }
                        })
                        if (obj[e.orgId]) {
                            obj[e.orgId] = obj[e.orgId] + jchillen
                        } else {
                            obj[e.orgId] = jchillen
                        }
                    } else {
                        obj[e.orgId] = 0;
                        this.tableData.push({ oneObj: e, twoObj: null, threeObj: null, col: 1 })
                    }
                })
                for (var key in obj) {
                    for (var i = 0; i < this.tableData.length; i++) {
                        if (key == this.tableData[i].oneObj.orgId) {
                            this.tableData[i].oneCol = obj[key] ? obj[key] : 1
                            break
                        }
                    }
                }

                console.log(obj);
                console.log(this.tableData)
                this.isShow = true;
            })
        },
        uniqueBy(arr, key) {
            const seen = new Set();
            return arr.filter((item) => {
                const seenValue = seen.has(item[key]);
                seen.add(item[key]);
                return !seenValue;
            });
        },
        toPdAdmin(adminList, userId) {
            var isYy = false;
            for (var i = 0; i < adminList.length; i++) {
                var e = adminList[i];
                if (e.userId == userId) {
                    isYy = true
                    break;
                }
            }
            return isYy
        },
        /** 修改按钮操作 */
        handleUpdate(rows) {
            var row = JSON.parse(JSON.stringify(rows))
            console.log(row)
            if (!row.$$data) {
                row.$$data = row
                row.id = row.orgId;
                row.label = row.orgName;
            }
            if (row.$$data.orgType == 5) {
                this.showTit = '学校'
            } else if (row.$$data.orgType == 4) {
                this.showTit = '区县'
            } else if (row.$$data.orgType == 3) {
                this.showTit = '市级'
            } else {
                this.showTit = '组织'
            }
            this.activeBcObj = row;
            this.UpdateObj.orgId = row.id;
            this.UpdateObj.orgName = row.label;
            this.UpdateObj.adminUsername = row.$$data.adminUsername;
            this.UpdateObj.adminUserId = row.$$data.adminUserId;
            this.UpdateObj.orgNature = row.$$data.orgNature;
            this.UpdateObj.endTime = row.$$data.endTime;
            this.UpdateObj.beginTime = row.$$data.beginTime;
            this.UpdateObj.adminList = row.$$data.adminList;
            this.UpdateObj.isAide = row.$$data.isAide?row.$$data.isAide:0;
            if (this.UpdateObj.adminList.length == 0) {
                this.UpdateObj.adminList.push({ username: '' })
            }
            this.customOrgUpdateShow = true;
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            if (!row.id) {
                row.id = row.orgId
            }
            orgDelete({ orgId: row.id }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getOrgListTree()
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
    }
};
</script>
<style scoped>
.editbox .el-button--text {
    padding: 0;
    height: auto;
}

.editbox {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 100%;
    position: absolute;
    min-height: 100%;
    line-height: 100%;
    background-color: rgb(0 0 0 / 75%);
}

.zdyedit .sss {
    min-height: 30px;
    line-height: 30px;
}

.zdyedit {
    position: relative;
}

.zdyedit:hover .editbox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.custom /deep/ .el-checkbox__input {
    position: absolute;
    bottom: 0;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* height: 270px; */
    /* overflow-y: auto; */
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.cxc {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

/deep/ .tree-org-node {
    padding-left: 5px !important;
    padding-right: 5px !important;
    /* padding-top: 50px !important; */
}
</style>