import '@/styles/common.scss'; // common css

import { createApp } from 'vue'
import App from '@/App.vue'
import zbhRouter from '@/router'
import ElementPlus from 'element-plus'
import axios from 'axios'
import vue3TreeOrg from 'vue3-tree-org';
import "vue3-tree-org/lib/vue3-tree-org.css";

import store from './store';

// import 'mavon-editor/dist/css/index.css';
import '@/styles/elementcss.css';
// import 'element-plus/dist/index.css'
/*图标*/
import * as ElementPlusIcons from '@element-plus/icons-vue'
import "@/assets/icon/iconfont.css"
/*国际化*/
import zhCn from 'element-plus/es/locale/lang/zh-cn'
/*工具类*/
import { Encrypt } from "@/utils/secret"
import Storage from "@/utils/storage"
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
import VueDragResizeRotate from "@gausszhou/vue3-drag-resize-rotate";
import "@gausszhou/vue3-drag-resize-rotate/lib/bundle.esm.css";
/*消息提示*/
import { ElMessage, ElMessageBox } from "element-plus";
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIcons)) {
    app.component(key, component)
}

// 页码组件
import Pagination from '@/components/Pagination';
app.component('Pagination', Pagination);

import perviewCanvas from '@/components/perviewCanvas'
app.component('PerviewCanvas', perviewCanvas);

import canvasExport from '@/components/canvasExport'
app.component('canvasExport', canvasExport);

import canvasbox from '@/components/canvasbox'
app.component('Canvasbox', canvasbox);

import canvasWritePlay from '@/components/canvasWritePlay'
app.component('CanvasWritePlay', canvasWritePlay);

import choosePeople from "@/components/choosePeople"
app.component('choosePeople', choosePeople);

import OSS from "ali-oss"

app.config.globalProperties.$oss = OSS

/*全局配置*/
app.config.globalProperties.$encrypt = Encrypt
app.config.globalProperties.$message = ElMessage
app.config.globalProperties.$storage = Storage
app.config.globalProperties.$router = zbhRouter

// 跳转页面
app.config.globalProperties.toPage = function (url, query) {
    console.log(url, query);
    this.$router.push({ path: url, query: query });
};

app.config.globalProperties.setHms = function (e) {
    if (e) {
        if(e.toString().length == 10){
            e = Number(e)*1000
        }
        const date = new Date(e);
        date.setSeconds(0);
        return date.getTime();
    }else{
        return ''
    }
};

// 跳转到指定URL，替换history栈中最后一个记录
app.config.globalProperties.replace = function (url, query) {
    // try {
    //     if (query && Object.keys(query).length > 0) {
    //         const q = Base64.encode(JSON.stringify(query));
    //         query = {
    //             q
    //         };
    //     }
    // } catch (e) {
    //     console.error(e);
    // }
    this.$router.replace({ path: url, query });
};

window.$router = zbhRouter
app.config.globalProperties.$confirm = ElMessageBox
/*监听缓存*/
app.config.globalProperties.$addStorageEvent = function (key, data) {
    let newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
        setItem: function (k, val) {
            localStorage.setItem(k, val);
            newStorageEvent.initStorageEvent(
                "changeSaasGroup",
                false,
                false,
                k,
                null,
                val,
                null,
                null
            );
            window.dispatchEvent(newStorageEvent);
        },
    };
    return storage.setItem(key, data);
};


app.directive('has-btn', (el, binding, vnode) => {
    var btnList = binding.value.split(',');
    if(btnList.indexOf(Storage.get("role")) == -1){
        el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
    }
})

// 引入vue-amap
import VueAMap from '@/components/aMap/aMap'
// 初始化vue-amap
// VueAMap.initAMapApiLoader({
//     // 高德的key
//     key: '76da2cefd70abe915d4aa3ee5f1cc6ca',
//     // 插件集合
//     plugin: [],

//     // 高德 sdk 版本，默认为 1.4.4
//     v: '1.4.4'
// });

app.use(VueAMap);


app.use(VueDragResizeRotate);
app.use(zbhRouter)
app.use(ElementPlus, { locale: zhCn, })
app.use(Avue)
app.use(Avue, { axios })
app.use(store)

app.use(vue3TreeOrg)

app.mount('#app')
