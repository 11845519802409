<template>
    <div class="app-container">
        <el-card class="mb15">
            <el-row>
                <el-col :span="16" class="mb0 disnone" style="display: flex;">
                    <!-- <el-upload v-model:file-list="filesList" class="upload-demo"
                            action="https://www.jingzhunjy.com/businessservice/user/batchImport" :on-change="handleChange"
                            :headers="{ token }" :on-remove="handleRemove" :data="{ orgId: orgId }" accept=".xlsx"
                            :before-upload="beforeUpload"> -->
                    <el-button type="primary" @click="excelAdd">导入用户</el-button>
                    <!-- </el-upload> -->
                    <el-button class="ml20" type="primary" @click="downloadTemplate">下载模板</el-button>
                    <el-badge :hidden="applyNum == 0" :showZero="false" :value="applyNum" class="item">
                        <el-button class="ml20" type="primary" @click="userReview">用户审核</el-button>
                    </el-badge>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">

                <el-row class="mt15">
                    <el-col :span="6" class="mb0">
                        <el-form-item label="身份">
                            <el-select @change="getList()" clearable v-model="queryParams.roleType" placeholder="请选择身份">
                                <el-option v-for="item in userRoleList" :label="item.dictValue" :value="item.dictKey" />
                                <!-- <el-option :label="'教导员'" :value="'教导员'" />
                                <el-option :label="'督导'" :value="'督导'" /> -->
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="职别">
                            <el-select @change="getList()" clearable v-model="queryParams.teacherRank" placeholder="请选择职别">
                                <el-option v-for="item in teacherRankList" :label="item.dictValue" :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="性别">
                            <el-select @change="getList()" clearable v-model="queryParams.gender" placeholder="请选择性别">
                                <el-option :label="'男'" :value="'0'" />
                                <el-option :label="'女'" :value="'1'" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="所属组织">
                            <el-tree-select @change="getList()" clearable
                                :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                                v-model="queryParams.orgId" default-expand-all :data="treeData" check-strictly
                                :render-after-expand="true" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="mt15">
                    
                    <el-col :span="24" class="mr0 mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.trueName" placeholder="请输入姓名或账号" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                    :height="`calc(100vh - ${role == 1 || role == 2 || role == 3 ? '420' : '370'}px)`" v-loading="loading"
                    :data="dataList" highlight-current-row>
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column label="账号" width="120" prop="username">
                    </el-table-column>
                    <el-table-column width="120" label="姓名" prop="trueName" />
                    <el-table-column width="60" label="性别" prop="gender">
                        <template #default="scope">
                            <div v-if="scope.row.gender == 0" class="text-one">男</div>
                            <div v-if="scope.row.gender == 1" class="text-one">女</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="130" label="职称" prop="teacherRankName" />
                    <el-table-column width="130" label="身份" prop="roleName" />
                    <el-table-column width="130" label="角色" prop="adminRole">
                        <template #default="scope">
                            <div v-if="scope.row.adminRole == 1">超级管理员</div>
                            <div v-if="scope.row.adminRole == 2">平台管理员</div>
                            <div v-if="scope.row.adminRole == 3">普通用户</div>
                        </template>
                    </el-table-column>

                    <el-table-column width="200" label="所属组织" prop="orgName" />
                    <el-table-column width="70" label="学段" prop="periodName" />
                    <el-table-column width="140" label="学科" prop="subjectName" />

                    <el-table-column fixed="right" width="430" label="操作" class-name="small-padding fixed-width">
                        <template #default="scope">
                            <div style="display: flex;" class="disnone">
                                <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">编辑
                                </el-button>
                                <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                    icon-color="#626AEF" title="确定删除该用户" @confirm="handleDelete(scope.row)">
                                    <template #reference>
                                        <el-button size="mini" type="danger">删除
                                        </el-button>
                                    </template>
                                </el-popconfirm>
                                <el-button class="ml15" size="mini" type="primary" @click="handHonor(scope.row)">荣誉
                                </el-button>
                                <!-- <el-upload v-model:file-list="filesList" class="upload-demo ml15"
                                action="https://www.jingzhunjy.com/businessservice/cert/upload" :on-change="handleSCChange"
                                :headers="{ token }" :data="{ userId: scope.row.userId }"
                                :before-upload="beforeAvatarUpload">
                                <el-button type="primary">上传证书</el-button>
                            </el-upload> -->
                                <el-button class="ml15" size="mini" type="primary" @click="handleLook(scope.row)">证书
                                </el-button>
                                <el-button v-has-btn="'1'" v-if="scope.row.adminRole == 3" class="ml15" size="mini"
                                    type="primary" @click="handleAdminAdd(scope.row)">设置平台管理
                                </el-button>
                                <el-button v-has-btn="'1'" v-if="scope.row.adminRole == 2" class="ml15" size="mini"
                                    type="primary" @click="handleAdminCancel(scope.row)">撤销平台管理
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="zdyfy">
                <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="total > 0" :total="total"
                    :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
            </div>

        </el-card>
        <el-dialog draggable :title="'证书'" v-model="fileShow" width="80%">
            <div class="fl-warp" style="display: flex;">
                <div v-for="(item, index) in showFileList" class="csbbb">
                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled" icon-color="#626AEF"
                        title="确定删除该证书" @confirm="delBookId(item.certId, index)">
                        <template #reference>
                            <el-icon :size="20" class="eicon" style="cursor: pointer;z-index: 99;color: red;">
                                <Delete />
                            </el-icon>
                        </template>
                    </el-popconfirm>
                    <el-image fit="cover" :initial-index="index" :preview-src-list="showUrlList"
                        style="width: 180px;height:130px;margin-bottom: 10px;object-fit: cover;" :src="item.certUrl" alt=""
                        class="image" />
                </div>
                <div class="csbbb">

                    <el-upload v-model:file-list="filesList" class="avatar-uploader"
                        action="https://www.jingzhunjy.com/businessservice/cert/upload" :show-file-list="false"
                        :on-change="handleSCChange" :before-upload="beforeAvatarUpload" :headers="{ token }"
                        :data="{ userId: selectObj.userId }">
                        <el-icon style="width: 180px;height:130px;" class="avatar-uploader-icon">
                            <Plus />
                        </el-icon>
                    </el-upload>
                </div>
            </div>
            <!-- <div style="width: 100%;" v-if="showFileList.length == 0">
                <el-empty class="qun-empty" description="暂无证书" />
            </div> -->
        </el-dialog>
        <el-dialog @close="closeReport" title="导入用户" v-model="scShow" width="60%">
            <el-form ref="reportForm" label-width="100px">
                <el-row>
                    <el-form-item label="所属组织">
                        <el-tree-select clearable :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                            v-model="scOrgId" default-expand-all :data="treeData" check-strictly
                            :render-after-expand="true" />
                    </el-form-item>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center disnone" style="align-items: unset;">
                    <el-upload v-if="scOrgId" v-model:file-list="filesList" class="upload-demo"
                        action="https://www.jingzhunjy.com/businessservice/user/batchImport" :on-change="handleChange"
                        :headers="{ token }" :on-remove="handleRemove" :data="{ orgId: scOrgId }" accept=".xlsx"
                        :before-upload="beforeUpload">
                        <el-button type="primary">上 传</el-button>
                    </el-upload>
                    <el-button class="ml15" @click="scShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'荣誉管理'" v-model="honorShow" width="500px">
            <el-row>
                <el-col :span="8" v-for="item in 9">
                    <div style="width: 70%;margin: 0 auto;padding: 10px;">
                        <img class="cursor" @click="changeLight(item)" style="width: 100%;"
                            :src="require(`../../assets/rycj/${honorList.indexOf(item.toString()) == -1 ? 'ryh' : 'ry'}-${item}.png`)"
                            alt="">
                    </div>
                </el-col>
            </el-row>
        </el-dialog>
        <el-dialog draggable :title="'用户审核'" v-model="isReview" width="80%">
            <el-row>
                <div class="bre9e" style="width: 100%;">
                    <el-table header-cell-class-name="bgf2" show-overflow-tooltip :height="`50vh`" :data="applyList"
                        highlight-current-row>
                        <!-- <el-table-column type="selection" width="55" /> -->
                        <el-table-column type="index" label="序号" width="60" />
                        <el-table-column label="账号" width="120" prop="username">
                        </el-table-column>
                        <el-table-column width="120" label="姓名" prop="trueName" />
                        <el-table-column width="160" label="状态" prop="status">
                            <template #default="scope">
                                <div v-if="scope.row.status == 0">未审核</div>
                                <div v-if="scope.row.status == 1">审核通过</div>
                                <div v-if="scope.row.status == 2">审核拒绝</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="所属组织" prop="orgName">
                            <template #default="scope">
                                <div :title="scope.row.orgName" class="text-one">
                                    {{ scope.row.orgName }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column width="140" label="注册时间" prop="applyTime">
                            <template #default="scope">
                                {{ timestampToTime(scope.row.applyTime) }}
                            </template>
                        </el-table-column>

                        <el-table-column fixed="right" width="300" label="操作" class-name="small-padding fixed-width">
                            <template #default="scope">
                                <div style="display: flex;" class="disnone">
                                    <el-button v-if="scope.row.status == 0" size="mini" type="primary" icon=""
                                        @click="handleAgree(scope.row)">同意
                                    </el-button>
                                    <el-button size="mini" v-if="scope.row.status == 0" type="danger" icon=""
                                        @click="handleRefuse(scope.row)">拒绝
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="zdyfy" style="width: 100%;">
                    <pagination class="zdyfy" :background="false" :pageSizes="[10, 20, 30]" v-show="applyTotal > 0"
                        :total="applyTotal" :page.sync="applyParams.pageNum" :limit.sync="applyParams.pageSize"
                        @pagination="getApplyList" />
                </div>
            </el-row>
        </el-dialog>

    </div>
</template>

<script>
import { orgListTree, groupListAll, userListOrgUsers, orgListUserOrg } from "@/api/admin/system";
import {
    exportData
} from "@/api/demo/data";
import { timestampToTime, timestampToDate } from "@/utils/date";
import { queryDict, userList, userPageList, userDelete, userCerts, certDelete, userLightHonorOrCancel, adminAdd, adminCancel, regApplyAgree, regApplyDelete, regApplyPage, regApplyRefuse, regApplyHandleNum } from "@/api/admin/public";
import { download } from "@/api/adminservice/public";

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            scOrgId: '',
            isReview: false,
            scShow: false,
            fileShow: false,
            showFileList: [],
            showUrlList: [],
            userRoleList: [],
            teacherRankList: [],
            filesList: [],
            treeData: [],
            isVideo: 0,// 1 课 2 章 3 节
            isType: 1, // 1 课 2 章 3 节
            selectRow: {},
            scFa: false,
            subjectList: [],
            periodList: [],
            gradeList: [],
            timestampToDate,
            gAFileList: [],
            orgId: '',
            token: this.$storage.get("token"),
            courseClassifyList: [],
            statusList: [
                { name: '初始', value: 0 },
                { name: '已发布', value: 1 },
            ],
            typeList: [

            ],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 选中数组
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isFilter: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                orgId: '',
                trueName: '',
                status: '',
                title: '',
                roleName: '',
                teacherRankName: '',
                gender: '',
                gradeKey: '',
                subjectKey: '',
                type: '',
                versionKey: '',
                roleType: '',
                teacherRank: '',
                pageNum: 1,
                pageSize: 10,
            },
            applyParams: {
                pageNum: 1,
                pageSize: 10,
            },
            applyList: [],
            applyTotal: 0,
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            certList: [],
            honorShow: false,
            honorList: [],
            role: '',
            applyNum: 0,
        };
    },
    created() {
        this.role = this.$storage.get("role");
        this.orgId = JSON.parse(this.$storage.get("userInfo")).orgId
        this.getApplyNum();
        this.getOrgListTree();
        this.getQueryDict();
        this.getList();
    },
    activated() {

        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        getApplyNum() {
            regApplyHandleNum().then(res => {
                this.applyNum = res.data.data;
            })
        },
        handleAgree(row) {
            regApplyAgree({ applyId: row.applyId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("同意成功");
                    this.getApplyList();
                    this.getApplyNum();
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleRefuse(row) {
            regApplyRefuse({ applyId: row.applyId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("拒绝成功");
                    this.getApplyList();
                    this.getApplyNum();
                    this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleAdminAdd(row) {
            adminAdd({ userId: row.userId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('操作成功')
                    this.getList()
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        handleAdminCancel(row) {
            adminCancel({ userId: row.userId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('操作成功')
                    this.getList()
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        changeLight(index) {
            var len = this.honorList.indexOf(index.toString())
            console.log(len);
            this.$confirm('确认' + (len == -1 && len != 0 ? '点亮' : '取消') + '该荣誉?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                userLightHonorOrCancel({ honorNo: index, userId: this.selectObj.userId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success('操作成功')
                        if (len != -1) {
                            this.honorList.splice(len, 1)
                        } else {
                            this.honorList.push(index.toString())
                        }
                        this.selectObj.honor = this.honorList.toString()
                    } else {
                        this.$message.error('操作失败')
                    }
                })
            }).catch(() => {
            });
        },
        handHonor(row) {
            this.honorList = [];
            this.selectObj = row;
            if (row.honor) {
                this.honorList = row.honor.split(',')
                if (this.honorList[this.honorList.length - 1] == '') {
                    this.honorList.splice(this.honorList.length - 1, 1)
                }
            }

            this.honorShow = true;
            console.log(row, this.honorList);
        },
        handleLook(row) {
            this.selectObj = row;
            this.showFileList = [];
            this.showUrlList = [];
            userCerts({ userId: row.userId }).then(reson => {
                this.showFileList = reson.data.data;
                reson.data.data.forEach(e => {
                    this.showUrlList.push(e.certUrl)
                })
            })
            console.log(this.selectObj);
            this.fileShow = true;
        },
        delBookId(certId, index) {
            certDelete({ certId }).then(res => {
                if (res.data.code == 1) {
                    this.showFileList.splice(index, 1);
                    this.showUrlList.splice(index, 1);
                    this.$message.success('删除成功');
                } else {
                    this.$message.error('操作失败');
                }
            })
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        beforeUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                this.$message.error('文件必须是.xlsx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.scOrgId = '';
                    this.scShow = false;
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        handleSCChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('上传成功')
                    this.showUrlList = [];
                    userCerts({ userId: this.selectObj.userId }).then(reson => {
                        this.showFileList = reson.data.data;
                        reson.data.data.forEach(e => {
                            this.showUrlList.push(e.certUrl)
                        })
                    })
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('上传失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('上传失败')
            }
        },
        excelAdd() {
            this.scOrgId = ''
            this.scShow = true;
        },
        userReview() {
            this.queryParams.pageNum = 1;
            this.queryParams.pageSize = 10;
            this.getApplyList();
            this.isReview = true;
        },
        getApplyList(e) {
            if (e) {
                this.queryParams.pageNum = e.page;
                this.queryParams.pageSize = e.limit;
            }
            regApplyPage(this.applyParams).then(res => {
                this.applyList = res.data.data.list;
                this.applyTotal = res.data.data.total;
                console.log(res);
            })
        },
        downloadTemplate() {
            download('https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/excel/%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx', '用户导入模板')
        },
        getOrgListTree() {
            orgListUserOrg().then(res => {
                this.treeData = res.data.data;
            })
        },
        goFileDetail(item) {
            console.log(item);
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleDiscuss(row) {
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: row.lessonId }
            })
            window.open(routeUrl.href, '_blank')
        },
        handleExceed(e) {
            console.log(e)
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        getQueryDict() {
            queryDict({ code: 'class_version' }).then(res => {
                this.courseClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'user_role' }).then(res => {
                this.userRoleList = res.data.data;
            })
            queryDict({ code: 'teacher_rank' }).then(res => {
                this.teacherRankList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        handleGA(row) {
            this.scFa = true;
            this.selectRow = row;
            console.log(this.selectRow)
            // lessonGaFiles({lessonId:row.lessonId}).then(res=>{

            // })
        },
        handleCurrentChange(val) {
            console.log(val)
            // this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            if (this.$storage.get("role") == 1 || this.$storage.get("role") == 2 || this.$storage.get("role") == 3) {
                if (e) {
                    this.queryParams.pageNum = e.page;
                    this.queryParams.pageSize = e.limit;
                }
                userPageList(this.queryParams).then(res => {
                    this.loading = false;

                    this.dataList = res.data.data.list;
                    this.total = res.data.data.total;
                    // this.dataList.forEach(e=>{
                    //     var memberTxtList = [];
                    //     e.memberList.forEach(j=>{
                    //         memberTxtList.push(j.memberName)
                    //     })
                    //     e.memberTxt = memberTxtList.join('、')
                    // })
                });
            } else {
                userListOrgUsers().then(res => {
                    res.data.data.orgList.forEach(e => {
                        e.title = e.orgName;
                        if (e.groupList) {
                            e.groupList.forEach(j => {
                                j.title = j.groupName
                            })
                        }
                    })
                    if (this.queryParams.roleType !== '') {
                        res.data.data.userList = res.data.data.userList.filter(itemA => {
                            return itemA.roleType == this.queryParams.roleType
                        })
                    }
                    if (this.queryParams.teacherRank !== '') {
                        res.data.data.userList = res.data.data.userList.filter(itemA => {
                            return itemA.teacherRank == this.queryParams.teacherRank
                        })
                    }
                    if (this.queryParams.gender !== '') {
                        res.data.data.userList = res.data.data.userList.filter(itemA => {
                            return itemA.gender == this.queryParams.gender
                        })
                    }
                    if (this.queryParams.orgId !== '') {
                        res.data.data.userList = res.data.data.userList.filter(itemA => {
                            return itemA.orgId == this.queryParams.orgId
                        })
                    }
                    if (this.queryParams.trueName !== '') {
                        res.data.data.userList = res.data.data.userList.filter(itemA => {
                            return itemA.trueName.toString().indexOf(this.queryParams.trueName) != -1
                        })
                    }
                    this.dataList = res.data.data.userList;
                    this.loading = false;
                })
            }

        },
        /** 删除按钮操作 */
        handleDelete(row) {
            userDelete({ userId: row.userId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getList()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/teachResearchDetail",
                query: {
                    groupId: row.groupId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/teachResearchAdd",
            });
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/uesrAdd",
                query: {
                    mobile: row.username,
                }
            });
        },
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.csbbb .eicon {
    display: none;
    position: absolute;
    right: 20px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .eicon {
    display: block;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>