<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>群组管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!groupId">新增群组</el-breadcrumb-item>
                <el-breadcrumb-item v-if="groupId">编辑群组</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="所属组织" prop="orgId">
                            <el-tree-select @node-click="changeOrg"
                                :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }" v-model="form.orgId"
                                default-expand-all :data="treeData" check-strictly :render-after-expand="true" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群组性质" prop="groupNature">
                            <el-select style="width: 100%;" v-model="form.groupNature" placeholder="群组性质">
                                <el-option v-for="item in natureList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群组名称" prop="groupName">
                            <el-input v-model="form.groupName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群组头像" prop="groupAvatar">
                            <el-upload class="avatar-uploader" action="https://www.jingzhunjy.com/businessservice/teachingGroup/uploadAvatar"
                                :show-file-list="false" :on-success="handleAvatarSuccess" :headers="{ token }"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="form.groupAvatar" :src="form.groupAvatar" class="avatar" style="position:absolute" />
                                <el-icon class="avatar-uploader-icon" style="font-size:100px;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>

                    <el-col :span="8">
                        <el-form-item label="学段" prop="periodKey">
                            <el-select @change="changePeriod" v-model="form.periodKey" placeholder="请选择学段">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="年级" prop="gradeKey">
                            <el-select @change="changeGrade" v-model="form.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="学科" prop="subjectKey">
                            <el-select v-model="form.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.groupNature == 2 || form.groupNature == 3">
                    <el-col :span="12">
                        <el-form-item label="简介" prop="groupIntroduce">
                            <el-input type="textarea" maxlength="1000" v-model="form.groupIntroduce" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="form.groupNature == 2 || form.groupNature == 3?'主持人账号':'群主账号'" prop="leadUsername">
                            <el-input v-model="form.leadUsername" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="form.groupNature == 2 || form.groupNature == 3?'主持人姓名':'群主姓名'" prop="leadName">
                            <el-input v-model="form.leadName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="群组成员" prop="memberList">
                            <el-table ref="memberList" border :data="tableData" style="width: 100%" max-height="250">
                                <el-table-column prop="adminAccount" label="账号">
                                    <template #default="scope">
                                        <el-input v-if="!scope.row.memberId" @blur="validateUsername(scope.row)"
                                            v-model="scope.row.memberAccount" placeholder="最多16个字" maxlength="16" />
                                        <div v-else>{{ scope.row.memberAccount }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="adminName" label="姓名">
                                    <template #default="scope">
                                        {{ scope.row.memberName }}
                                        <el-icon v-if="scope.row.memberRole == 1"><Avatar /></el-icon>
                                    </template>
                                </el-table-column>
                                <el-table-column fixed="right" label="操作" width="160">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small" v-if="scope.row.memberRole == 1"
                                            @click.prevent="revokeAdministrator(scope.$index)">
                                            撤销管理员
                                        </el-button>
                                        <el-button link type="primary" size="small" v-if="scope.row.memberRole == 2"
                                            @click.prevent="setAdministrator(scope.$index)">
                                            设置管理员
                                        </el-button>
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteRow(scope.$index)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button plain type="primary" class="mt10" style="width: 100%"
                                @click="onAddItem">增加</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
        </el-card>
        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { queryUserInfo } from "@/api/admin/public";
import { orgListTree, groupCreate, groupInfo, groupUpdate,orgListUserOrg } from "@/api/admin/system";
import { queryDict, userList } from "@/api/admin/public";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            treeData: [],
            groupId: '',
            jAFileList: [],
            xAFileList: [],
            kJFileList: [],
            xTFileList: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            versionKeyList: [],
            natureList: [],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                orgId: '',
                groupNature: '',
                groupName: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                groupIntroduce: '',
                leadUsername: JSON.parse(this.$storage.get('userInfo')).username,
                leadUser: JSON.parse(this.$storage.get('userInfo')).userId,
                leadName: JSON.parse(this.$storage.get('userInfo')).trueName,
                groupAvatar:'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/group/cover/group.png'
            },
            tableData: [
            ],
            // 表单校验
            rules: {
                // periodKey: [
                //     { required: true, message: "请选择学段", trigger: "change" },
                // ],
                groupNature: [
                    { required: true, message: "请选择群组性质", trigger: "change" },
                ],
                // gradeKey: [
                //     { required: true, message: "请选择年级", trigger: "change" },
                // ],
                // subjectKey: [
                //     { required: true, message: "请选择学科", trigger: "change" },
                // ],
                orgId: [
                    { required: true, message: "请选择组织", trigger: "change" },
                ],
                groupName: [
                    { required: true, message: "请输入群组名称", trigger: "blur" },
                ],
                groupIntroduce: [
                    { required: true, message: "请输入群组介绍", trigger: "blur" },
                ],
                leadUsername: [
                    { required: true, message: "请输入群主账号", trigger: "blur" },
                    { validator: this.twovalidateUsername, trigger: "blur" }
                ],
                // memberList: [
                //     { required: true, validator: this.checkOrgValidator }
                // ]
            },
            delFileList: [],
        };
    },
    created() {
        this.getOrgListTree();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.groupId) {
            this.groupId = this.$route.query.groupId
            console.log(this.form)
            this.getGroupInfo()
        } else {
        }
    },
    unmounted() {
    },
    computed: {
    },
    methods: {
        checkOrgValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.tableData.length; i++) {
                var e = this.tableData[i];
                if (e.memberAccount == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.tableData.splice(index, 1)
                // this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        setAdministrator(index) {
            this.tableData[index].memberRole = 1;
        },
        revokeAdministrator(index) {
            this.tableData[index].memberRole = 2;
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                memberAccount: '',
                memberName: '',
                memberRole: 2,
                memberUserId: '',
                formIds: ''
            })
            setTimeout(() => {
                this.$refs.memberList.setScrollTop(1000000);
            })
        },
        twovalidateUsername(rule, value, callback) {
            queryUserInfo({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    this.form.leadUser = res.data.data.userId;
                    this.form.leadName = res.data.data.trueName;
                    callback()
                }
            })
        },
        validateUsername(value,) {
            if (value.memberAccount) {
                if (value.memberAccount == this.form.leadUsername) {
                    value.memberAccount = ''
                    value.memberName = '';
                    value.memberUserId = '';
                    this.$message.error("不能和群主账号一样")
                } else {
                    queryUserInfo({ mobile: value.memberAccount }).then(res => {
                        const result = res.data.data
                        if (!result) {
                            value.memberAccount = ''
                            value.memberName = '';
                            value.memberUserId = '';
                            this.$message.error("请输入正确账号")
                        } else {
                            value.memberName = res.data.data.trueName
                            value.memberUserId = res.data.data.userId
                        }
                    })
                }

            } else {
                value.memberName = '';
                value.memberUserId = '';
            }

        },
        changeOrg(e) {
            console.log(e);
            // if (e.orgType == 3) {
            //     this.form.groupType = 1
            // } else if (e.orgType == 4) {
            //     this.form.groupType = 2
            // } else if (e.orgType == 5) {
            //     this.form.groupType = 3
            // }

        },
        getOrgListTree() {
            orgListUserOrg().then(res => {
                this.treeData = res.data.data;
                console.log(this.treeData);
            })
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.courseCover != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getGroupInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                groupInfo({ groupId: this.groupId }).then(res => {
                    this.form = res.data.data;
                    res.data.data.memberList.splice(0, 1);
                    this.tableData = res.data.data.memberList;
                    console.log(this.tableData);
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey == j.dictKey) {
                                    this.subjectList = j.childrenList;
                                }
                            })
                        }
                    })
                })
            })

        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)
        },
        handleXAChange(file) {
            console.log(file);
        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'class_version' }).then(res => {
                this.versionKeyList = res.data.data;
            })
            queryDict({ code: 'group_news_type' }).then(res => {
                this.natureList = res.data.data;
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.groupAvatar = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)

            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.form.memberList = this.tableData;
                    this.form.memberList.forEach((e,i)=>{
                        if(e.memberUserId == ''){
                            this.form.memberList.splice(i, 1)
                        }
                    })
                    if (this.groupId) {
                        groupUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/teachResearch', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        groupCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/teachResearch', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            // this.jAFileList = [];
            this.replace('/teachResearch')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>