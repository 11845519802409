<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>用户管理</a></el-breadcrumb-item>
                <!-- <el-breadcrumb-item v-if="!$route.query.data">新增新闻资讯</el-breadcrumb-item> -->
                <el-breadcrumb-item>编辑用户</el-breadcrumb-item>

            </el-breadcrumb>
        </div>
        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form style="padding-bottom: 15px;" ref="form" :model="form" :rules="rules" label-width="60px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="trueName">
                            <el-input v-model="form.trueName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="性别:" prop="gender">
                            <el-radio-group v-model="form.gender">
                                <el-radio :label="0">男</el-radio>
                                <el-radio :label="1">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="身份:" prop="roleName">
                            <el-select v-model="form.roleType" placeholder="请选择学段">
                                <el-option v-for="item in roleTypeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="学段" prop="periodKey">
                            <el-select @change="changePeriod" v-model="form.periodKey" placeholder="请选择学段">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="年级" prop="gradeKey">
                            <el-select @change="changeGrade" v-model="form.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学科" prop="subjectKeyList">
                            <el-select multiple style="width: 100%;" v-model="form.subjectKeyList" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="职称:" prop="teacherRankName">
                            <el-select v-model="form.teacherRank" placeholder="请选择学段">
                                <el-option v-for="item in teacherRankList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="归属:" prop="orgName">
                            <el-tree-select style="width: 100%;" filterable
                                :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }" v-model="form.orgId"
                                default-expand-all :data="treeData" check-strictly :render-after-expand="true" />
                            <!-- <el-select style="width: 100%;" filterable v-model="form.orgId" placeholder="请选择学校">
                                <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName"
                                    :value="item.orgId" />
                            </el-select> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="手机号:" prop="username">
                            {{ form.username }}
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { userCenterInfo, userCenterUpdate, queryDict, authserviceSchoolList, queryUserInfo, userUpdate } from "@/api/admin/public";
import { bannerCreate, bannerUpdate } from "@/api/admin/news";
import { orgListTree,orgListUserOrg } from "@/api/admin/system";
import TEditor from '@/components/TEditor.vue';

export default {
    name: "demoAdd",
    components: {
        TEditor
    },
    data() {
        return {
            orgList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            roleTypeList: [],
            teacherRankList: [],
            // 表单参数
            form: {
                title: '',
                content: '',
                appContent: '',
                bannerUrl: '',
                type: 0,
                avatar: '',
                gender: 0,
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                subjectKeyList: [],
            },
            treeData: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            // 表单校验
            rules: {
            },
            mobile: '',
        };
    },
    created() {
        this.getOrgListTypeOrg();
    },
    mounted() {
        if (this.$route.query.mobile) {
            this.mobile = this.$route.query.mobile
            this.getUserCenterInfo()

        }
    },
    computed: {
    },
    methods: {
        getOrgListTypeOrg() {
            orgListUserOrg().then(res => {
                this.treeData = res.data.data;
            })
            authserviceSchoolList().then(res => {
                this.orgList = res.data.data;
                console.log(res);
            })
        },
        getUserCenterInfo() {
            queryDict({ code: 'user_role' }).then(res => {
                this.roleTypeList = res.data.data;
            })
            queryDict({ code: 'teacher_rank' }).then(res => {
                this.teacherRankList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                queryUserInfo({ mobile: this.mobile }).then(res => {
                    this.form = res.data.data;
                    if (this.form.subjectKey) {
                        this.form.subjectKeyList = this.form.subjectKey.split(',')
                    }
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey) {
                                    if (this.form.gradeKey == j.dictKey) {
                                        this.subjectList = j.childrenList;
                                    }
                                } else {
                                    j.childrenList.forEach(m => {
                                        this.subjectList.push(m)
                                    })
                                }

                            })
                        }
                    })
                    if (!this.form.avatar || this.form.avatar == '') {
                        if (this.form.gender) {
                            if (this.form.gender == 0) {
                                this.form.avatar = 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/nan.png'
                            } else {
                                this.form.avatar = 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/nv.png'
                            }
                        }
                    }
                    console.log(res);
                    this.subjectList = this.deWeightTwo();
                })
            })
        },
        deWeightTwo() {
            var temp = [];
            this.subjectList.forEach(function (a) {
                var check = temp.every(function (b) {
                    return a.dictKey !== b.dictKey;
                })
                check ? temp.push(a) : ''
            })
            return temp;
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.bannerUrl != '') {
                callback()
            } else {
                callback(new Error('请上传轮播图图片'))
            }
        },
        checkContentValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.content != '') {
                callback()
            } else {
                callback(new Error('请填写内容'))
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.avatar = null;
                this.form.avatar = uploadFile.response.data;
                this.$emit('changeImg', uploadFile.response.data)
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            if (this.form.subjectKeyList) {
                if (this.form.subjectKeyList.length != 0) {
                    this.form.subjectKey = this.form.subjectKeyList.toString();
                }
            }

            userUpdate(this.form).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('保存成功')
                    this.replace('/uesrManage', { isSx: 1 })
                } else {
                    this.$message.error('保存失败')
                }
            })
        },

        // 取消按钮
        cancel() {
            this.replace('/uesrManage')
            // this.$emit('isTg', true)
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey) {
                            if (this.form.gradeKey == j.dictKey) {
                                this.subjectList = j.childrenList;
                            }
                        } else {
                            j.childrenList.forEach(m => {
                                this.subjectList.push(m)
                            })
                        }
                    })
                }
            })
            this.subjectList = this.deWeightTwo();
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>