<template>
    <div class="app-container" :data-wz="1" @dragenter="dragName" @dragstart="dragStart" @dragend="dragend">
        <div class="x-nav" :data-wz="1" @dragenter="dragName" @dragstart="dragStart" @dragend="dragend">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>培训视频</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="isLook == 0">上传视频</el-breadcrumb-item>
                <el-breadcrumb-item v-if="isLook == 1">查看视频</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row>
            <el-col :span="6">
                <div :data-wz="1" @dragenter="dragName" @dragstart="dragStart" @dragend="dragend" id="vdWidth"
                    class="custtoo" style="margin-right: 15px;height: calc(100vh - 140px);overflow: auto;">
                    <el-tree :data="dataSource" highlight-current ref="tree" @node-click="changeTree"
                        :props="{ label: 'courseName', id: 'courseId', children: 'childrenList' }" node-key="courseId"
                        default-expand-all :expand-on-click-node="false">
                        <template #default="{ node, data }">
                            <div :style="activeCourseObj.courseId && (activeCourseObj.courseId == data.courseId) ? 'background:#ff7f2e;color:#fff;display: flex;' : 'display: flex;'"
                                :data-courseId="data.courseId" :data-level="node.level" class="custom-tree-node"
                                :draggable="true" @dragenter="dragName" @dragstart="dragStart" @dragend="dragend">
                                <div :data-courseId="data.courseId" :data-level="node.level"
                                    style="flex: 1;align-items: center; display: flex;" :title="node.label">
                                    <el-icon :size="20" v-if="data.videoList && data.videoList.length != 0">
                                        <VideoPlay /> &nbsp;
                                    </el-icon>
                                    <div :data-courseId="data.courseId" :data-level="node.level"
                                        :style="'width:' + (vdWidth - 150) + 'px;'" class="text-one" style="">{{ node.label
                                        }}
                                    </div>
                                </div>
                                <div v-if="isLook == 0" :data-courseId="data.courseId" :data-level="node.level">
                                    <a title="新增视频" v-if="isType == node.level" style="margin-left: 8px"
                                        @click.stop="addVideo(data)"><el-icon>
                                            <FolderAdd />
                                        </el-icon></a>
                                    <a title="新增" v-if="node.level != 3 && isVideo != node.level" style="margin-left: 8px"
                                        @click.stop="append(data)"><el-icon>
                                            <Plus />
                                        </el-icon></a>
                                    <a title="编辑" style="margin-left: 8px" @click.stop="editCourse(data)"><el-icon>
                                            <EditPen />
                                        </el-icon></a>
                                    <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                        icon-color="#626AEF" title="确定删除该章节及其视频附件" @confirm="delCourse(node, data)">
                                        <template #reference>
                                            <a title="删除" v-if="node.level != 1" style="margin: 0 8px"> <el-icon>
                                                    <Delete />
                                                </el-icon></a>
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </div>
                        </template>
                    </el-tree>
                </div>
            </el-col>
            <el-col :span="18">
                <el-card shadow="never" style="height: calc(100vh - 140px);overflow: auto;" :data-wz="1"
                    @dragenter="dragName" @dragstart="dragStart" @dragend="dragend">
                    <el-row>
                        <el-col :span="8" v-for="item in activeObj.videoList">
                            <div :data-wz="1" :data-videoId="item.videoId" style="" class="tstih" :draggable="true"
                                @dragenter="dragName" @dragstart="dragStart" @dragend="dragend">
                                <div :data-wz="1" :data-videoId="item.videoId">
                                    <video v-if="isshow" :data-wz="1" :data-videoId="item.videoId" :id="item.videoId"
                                        :poster="item.videoUrl + '?x-oss-process=video/snapshot,t_1,m_fast'" preload="none"
                                        controls="controls" style="width: 100%;height: 200px;" :src="item.videoUrl"></video>
                                </div>
                                <div :data-wz="1" :data-videoId="item.videoId" style="text-align: center;" class="text-one"
                                    :title="item.videoTitle">{{
                                        item.videoTitle
                                    }}</div>
                                <div :data-wz="1" :data-videoId="item.videoId" class="start"
                                    style="position: absolute;right: 0;top:0;padding: 10px;background: #f1f1f1;">
                                    <el-icon v-if="isLook == 0" class="cursor" style="margin: 0 10px;"
                                        @click.stop="editFileName(item)" title="重命名">
                                        <EditPen />
                                    </el-icon>
                                    <el-icon v-if="isLook == 0" class="cursor" @click.stop="delFile(item)" title="删除">
                                        <Delete />
                                    </el-icon>
                                    <el-icon v-if="isLook == 0" class="cursor" style="margin: 0 10px;" title="上传资料"
                                        @click="addFile(item)">
                                        <DocumentAdd />
                                    </el-icon>
                                    <!-- <el-icon @click.stop="downloadFile(item)" title="下载" class="ml10">
                                        <Download />
                                    </el-icon> -->
                                    <el-icon class="cursor" @click="tofileShow(item)" title="查看资料">
                                        <Document />
                                    </el-icon>
                                    {{ item.fileList.length }}
                                </div>
                            </div>

                        </el-col>
                        <div style="width: 100%;" v-if="!activeObj.videoList || activeObj.videoList.length == 0">
                            <el-empty class="qun-empty" description="暂无视频" />
                        </div>
                    </el-row>
                    <!-- <el-row>
                        <el-col :span="12">
                            <el-form-item label="上传资料" prop="">
                                <el-upload v-model:file-list="fileList" class="upload-demo" :on-remove="handleRemove"
                                    :data="{ courseId: activeObj.courseId }"
                                    action="/businessservice/famousCourse/uploadVideoFile" :on-change="handleJAChange"
                                    :headers="{ token }" accept=".pdf,.ppt,.pptx" :before-upload="beforeJAUpload">
                                    <el-button type="primary">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            只允许上传.pdf,.ppt,.pptx格式文件。
                                        </div>
                                    </template>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                    </el-row> -->
                </el-card>
            </el-col>
        </el-row>
        <el-dialog draggable :title="'创建章节'" v-model="appCourseShow" width="60%">
            <el-form ref="appCourseForm" :model="appCourseForm" :rules="appCourseRules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="章节名称" prop="name">
                            <el-input v-model="appCourseForm.name" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitCourseForm">确 定</el-button>
                    <el-button @click="appCourseShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'编辑章节'" v-model="editCourseShow" width="60%">
            <el-form ref="editCourseForm" :model="appCourseForm" :rules="appCourseRules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="章节名称" prop="name">
                            <el-input v-model="appCourseForm.name" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submiteditCourseForm">确 定</el-button>
                    <el-button @click="editCourseShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'上传视频'" v-model="addVideoShow" width="60%">
            <el-radio-group v-model="uploadType">
                <el-radio-button label="文件" value="文件" />
                <el-radio-button label="链接" value="链接" />
            </el-radio-group>
            <div class="mt15" v-if="uploadType == '文件'">
                <el-upload ref="upload" :auto-upload="false" v-model:file-list="fileList" class="upload-demo" multiple
                    :on-change="handleJAChange" :data="{ courseId: activeObj.courseId }" :headers="{ token }"
                    accept=".MP4,.mp4,.avi,.AVI,.flv,.Flv" :http-request="handleUpload">
                    <el-button type="primary">点击上传</el-button>
                    <template #tip>
                        <div class="el-upload__tip">
                            只允许上传.MP4,.mp4,.avi,.AVI,.flv,.Flv格式文件。
                        </div>
                    </template>
                </el-upload>
            </div>
            <div class="mt15" v-if="uploadType == '链接'">
                <el-form-item required label="视频标题">
                    <el-input v-model="ljObj.videoTitle" maxlength="256" placeholder="请输入视频自定义标题" />
                </el-form-item>
                <el-form-item required label="课程地址">
                    <el-input v-model="ljObj.videoUrl" maxlength="256" placeholder="请输入课程地址" />
                </el-form-item>
            </div>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" v-if="uploadType == '文件'" @click="uploadVideo">确 定</el-button>
                    <el-button type="primary" v-if="uploadType == '链接'" @click="uploadljVideo">确 定</el-button>
                    <el-button @click="addVideoShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog draggable :title="'上传资料'" v-model="addFileShow" width="60%">
            <el-upload :auto-upload="false" v-model:file-list="fileList" class="upload-demo" multiple
                :on-change="handleJAChange" :before-upload="beforeJAUpload" :data="{ courseId: activeObj.courseId }"
                :headers="{ token }" accept=".pdf,.ppt,.pptx,.doc,.docx">
                <el-button type="primary">点击上传</el-button>
                <template #tip>
                    <div class="el-upload__tip">
                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                    </div>
                </template>
            </el-upload>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="uploadVideoFile">确 定</el-button>
                    <el-button @click="addFileShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog draggable :title="'附件'" v-model="fileShow" width="80%">
            <div style="display: flex;flex-wrap: wrap;">
                <div @click.stop="xz(item)" style="margin: 15px;" v-for="(item, index) in  showFileList "
                    class="mb10 cursor tstih">
                    <img style="width: 250px;height:300px;object-fit: cover;" :src="item.imgUrl + '/1.png'" alt="">
                    <div style="text-align: center;width: 250px;" :title="item.fileName" class="text-one">{{ item.fileName }}</div>
                    <div @click.stop="delVideolmFile(item, index)"
                        style="position: absolute;right: 0;top:0;padding: 10px;background: #f1f1f1;">
                        <el-icon v-if="isLook == 0" class="cursor" title="删除">
                            <Delete />
                        </el-icon>
                    </div>
                </div>
            </div>
            <div style="width: 100%;" v-if="showFileList.length == 0">
                <el-empty class="qun-empty" description="暂无附件" />
            </div>
        </el-dialog>

        <el-dialog draggable :title="'编辑文件名称'" v-model="editFileNameShow" width="60%">
            <el-form ref="editFileNameForm" :model="editFileNameForm" :rules="editFileNameRules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="视频名称" prop="videoTitle">
                            <el-input v-model="editFileNameForm.videoTitle" placeholder="最多64个字" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submiteditFileNameForm">确 定</el-button>
                    <el-button @click="editFileNameShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>

        <div class="loading-overlay" v-if="loadingVisible">
            <el-progress type="circle" :percentage="loadingPercentage" :stroke-width="8"></el-progress>
            <p class="tips_css">上传中，请等待...</p>
        </div>

    </div>
</template>

<script>
import flvjs from 'flv.js/dist/flv.min.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { trainrenameVideo, trainCourseInfo, trainCourseChapter, trainCourseUploadTrainVideos, trainCourseUpdateChapter, TrainCoursesListTreeFamous, trainCourseUploadVideoFile, trainDeleteVideo, trainCourseDel } from "@/api/admin/traCourse"
import { put, signatureUrl, getFileNameUUID } from '@/utils/tools'

import { courseVideoUpload, courseVideoMove } from "@/api/admin/famousCourse"
import { queryDict, userList, famousCourseDeleteVideoFile } from "@/api/admin/public";
import { ElLoading } from 'element-plus';

let OSS = require('ali-oss');
let client = new OSS({
    region: 'oss-cn-beijing',    //创建的时候，bucket所在的区域，华北2->oss-cn-beijing ；其他的可以去百度
    accessKeyId: 'LTAI5tCqpy6gH2Kfn5F7mS7H',// 阿里云控制台创建的AccessKey
    accessKeySecret: 'aVdDcMLLrcWYW5WBePRglyd3oqNIF1', //阿里云控制台创建的AccessSecret
    bucket: 'free-back'  //创建的bucket的名称
})

export default {
    name: "traCourseUploadVideo",
    components: {
    },
    data() {
        return {
            flvPlayerList: {},
            activeVideoId: '',
            uploadType: '文件',
            editFileNameShow: false,
            editFileNameRules: {
                videoTitle: [
                    { required: true, message: "请输入视频名称", trigger: "blur" }
                ]
            },
            editFileNameForm: {
                videoId: '',
                videoTitle: '',
            },
            ljObj: {
                videoTitle: '',
                videoUrl: '',
            },
            loadingPercentage: 0,//进度百分比
            timer: null,//定时器
            loadingVisible: false,//开启遮罩
            isTranDetail: 0,
            isLook: 0,
            isVideo: 0,// 1 课 2 章 3 节
            isType: 1, // 1 课 2 章 3 节
            addFileShow: false,
            showFileList: [],
            fileShow: false,
            addVideoShow: false,
            addVideoCourseId: '',
            addVideoId: '',
            appCourseRules: {
                name: [
                    { required: true, message: "请输入章节名称", trigger: "blur" }
                ],
            },
            appCourseShow: false,
            appCourseForm: {
                name: '',
                parentId: ''
            },
            isshow: false,
            fileList: [],
            treedata: [],
            dataSource: [],
            courseId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            courseClassifyList: [],
            activeObj: {},
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            editCourseShow: false,
            // 表单参数
            form: {
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                leadUser: '',
                teamUsers: '',
                teamuseList: [],
            },
            // 表单校验
            rules: {
            },
            delFileList: [],
            courseVideoObj: {},
            vdWidth: 250,
            activeCourseObj: {
                level: '',
                courseId: ''
            }
        };
    },
    created() {
        this.getQueryDict();
    },
    mounted() {
        this.vdWidth = (document.getElementById("vdWidth").clientWidth)
        this.courseId = this.$route.query.courseId
        if (this.$route.query.isLook) {
            this.isLook = this.$route.query.isLook
        }
        if (this.$route.query.isTranDetail) {
            this.isTranDetail = this.$route.query.isTranDetail
        }
        console.log(this.isLook)
        this.getfamousCourseInfo()
    },
    unmounted() {
    },
    computed: {
    },
    methods: {
        dragStart(cs) {
            console.log(cs);
            console.log(cs.target.dataset.videoid);
            if (cs.target.dataset.videoid) {
                this.activeVideoId = cs.target.dataset.videoid
            }
        },
        dragName(cs) {
            console.log(cs);
            console.log(cs.target.dataset.level);
            console.log(cs.target.dataset.courseid);
            console.log(cs.target.dataset.wz);
            if (cs.target.dataset.wz == 1) {
                this.activeCourseObj = {
                    level: '',
                    courseId: ''
                }
            }

            if (cs.target.dataset.level && cs.target.dataset.courseid) {
                this.activeCourseObj = {
                    level: cs.target.dataset.level,
                    courseId: cs.target.dataset.courseid
                }
            }
        },
        dragend(cs) {
            if (this.activeCourseObj.level && this.activeVideoId) {
                if (this.activeCourseObj.level != this.isType) {
                    this.activeCourseObj = {
                        level: '',
                        courseId: ''
                    }
                    this.activeVideoId = ''
                    this.$message.error('请拖拽至同等级的章节下')
                    return;
                }
                if (this.activeCourseObj.courseId && this.activeVideoId) {
                    courseVideoMove({ courseId: this.activeCourseObj.courseId, videoId: this.activeVideoId }).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("移动成功");
                            this.getfamousCourseInfo()
                        } else {
                            this.$message.error("操作失败")
                        }
                        this.activeCourseObj = {
                            level: '',
                            courseId: ''
                        }
                        this.activeVideoId = ''
                    })
                }
            }

            this.activeCourseObj = {
                level: '',
                courseId: ''
            }
            this.activeVideoId = ''
            console.log(cs);
            // console.log(cs.target.dataset.level);

        },
        submiteditFileNameForm() {
            this.$refs["editFileNameForm"].validate(valid => {
                if (valid) {
                    trainrenameVideo(this.editFileNameForm).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("创建成功");
                            this.editFileNameShow = false;
                            this.getfamousCourseInfo()
                        } else {
                            this.$message.error("操作失败")
                        }
                    })
                }
            });
        },
        editFileName(row) {
            console.log(row);
            this.editFileNameForm.videoTitle = row.videoTitle;
            this.editFileNameForm.videoId = row.videoId;
            this.editFileNameShow = true;
        },
        getFileName(name) {
            return name.substring(name.lastIndexOf("."));//.txt
        },
        handleUpload(option) {
            console.log(this.courseVideoObj.videoList);
            console.log(option);
            // 获取文件的后缀名
            // let objName = getFileNameUUID()
            var obj = option.file.name
            var index = obj.lastIndexOf(".");
            obj = obj.substring(index, obj.length);
            // 生成的文件名，保留文件后缀名，进行拼接      
            let objName = getFileNameUUID() + this.getFileName(option.file.name)
            // this.loadingInstance = ElLoading.service({
            //     lock: true,
            //     text: '提交中',
            //     background: 'rgba(0, 0, 0, 0.7)',
            // })
            // var courseVideoUpload
            // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
            // put(`/ygjy/kck/video/${objName}`, option.file).then(res => {
            //     console.log(res, 'res')
            //     this.courseVideoObj.videoList = [{
            //         videoUrl:res.url,
            //         videoTitle:option.file.name
            //     }]
            //     this.loadingInstance.close()
            //     courseVideoUpload(this.courseVideoObj).then(res=>{
            //         if(res.data.code == 1){
            //             this.addVideoShow = false;
            //             this.getfamousCourseInfo()
            //         }
            //     })
            //     // 上传成功之后，转换真实的地址
            //     signatureUrl(`/ygjy/kck/video/${objName}`).then(res => {
            //         console.log(res)
            //         this.loadingInstance.close()
            //     })
            // })

            this.loadingVisible = true;
            this.loadingPercentage = 0;
            this.multipartUpload(`/ygjy/kck/video/${objName}`, option.file).then(res => {
                console.log(res, 'res')
                this.loadingVisible = false;
                // 上传成功之后，转换真实的地址
                signatureUrl(`/ygjy/kck/video/${objName}`).then(res => {
                    console.log(res)
                    var lastQuestionMarkIndex = res.lastIndexOf("?")
                    var result = res;
                    if (lastQuestionMarkIndex !== -1) {
                        result = res.substring(0, lastQuestionMarkIndex); // 从开头到最后一个？之间的子字符串
                        console.log(result); // 输出结果为"https://www.example.com/path/"
                    } else {
                        console.log("URL不包含参数部分");
                    }
                    this.courseVideoObj.videoList = [{
                        videoUrl: result,
                        videoTitle: option.file.name
                    }]
                    courseVideoUpload(this.courseVideoObj).then(res => {
                        if (res.data.code == 1) {
                            this.addVideoShow = false;
                            this.getfamousCourseInfo()
                        }
                    })
                    this.loadingVisible = false;
                })
            })
        },
        async multipartUpload(ObjName, fileUrl) {
            try {
                let result = await client.multipartUpload(`${ObjName}`, fileUrl, {// 获取分片上传进度、断点和返回值。
                    progress: (p, cpt, res) => {
                        console.log(p);
                        this.loadingPercentage = parseInt(p * 100);
                    },
                    timeout: 600000000,
                    // 设置并发上传的分片数量。
                    parallel: 9999,
                    // 设置分片大小。默认值为1 MB，最小值为100 KB。
                    partSize: 1024 * 1024 * (50),
                })
                // ObjName为文件名字,可以只写名字，就直接储存在 bucket 的根路径，如需放在文件夹下面直接在文件名前面加上文件夹名称    
                return result
            } catch (e) {
                console.log(e)
                this.$message.error('上传失败')
                // this.loadingInstance.close()
                this.loadingVisible = false;
            }
        },
        delVideolmFile(item, index) {
            console.log(item);
            ElMessageBox.confirm(
                '是否删除该附件?',
                '删除',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                famousCourseDeleteVideoFile({ fileId: item.fileId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("删除成功");
                        this.showFileList.splice(index, 1)
                        this.getfamousCourseInfo()
                        // this.$emit('isTg', true);
                    } else {
                        this.$message.error("操作失败")
                    }
                })

            }).catch(() => {

            })
        },
        delFile(item) {
            console.log(item);
            var _this = this;
            ElMessageBox.confirm(
                '是否删除该视频及附件?',
                '删除',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                trainDeleteVideo({ videoId: item.videoId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("删除成功");
                        this.getfamousCourseInfo()
                        // this.$emit('isTg', true);
                    } else {
                        this.$message.error("操作失败")
                    }
                })

            }).catch(() => {

            })

            console.log(item);
        },
        xz(item) {
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
            // download(item.fileUrl, item.fileName)
        },
        tofileShow(item) {
            this.showFileList = item.fileList;
            this.fileShow = true;
        },
        addFile(item) {
            // console.log(item);
            this.addVideoId = item.videoId
            this.fileList = [];
            this.addFileShow = true;
        },
        uploadVideoFile() {
            console.log(this.activeObj.courseId)
            this.loadingInstance = ElLoading.service({
                lock: true,
                text: '提交中',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            console.log(this.fileList)
            let formdata = new FormData();
            this.fileList.map((item, index) => {
                console.log(item, index)
                var okey = 'files'
                formdata.append(okey, item.raw)
            })
            formdata.append('videoId', this.addVideoId)
            trainCourseUploadVideoFile(formdata).then(res => {
                if (res.data.code == 1) {
                    this.loadingInstance.close()
                    this.$message.success("新增成功");
                    this.addFileShow = false;
                    this.getfamousCourseInfo()
                    // this.$emit('isTg', true);
                } else {
                    this.loadingInstance.close()
                    this.$message.error("操作失败")
                }
            })
        },
        uploadljVideo() {
            if (this.ljObj.videoTitle == '') {
                this.$message.error('请输入视频标题');
                return
            }
            if (this.ljObj.videoUrl == '') {
                this.$message.error('请输入链接');
                return
            }
            this.courseVideoObj.courseId = this.addVideoCourseId;
            this.courseVideoObj.videoList = [];
            this.courseVideoObj.videoList.push(this.ljObj)
            courseVideoUpload(this.courseVideoObj).then(res => {
                if (res.data.code == 1) {
                    this.addVideoShow = false;
                    this.getfamousCourseInfo()
                }
            })
        },
        uploadVideo() {
            this.courseVideoObj.courseId = this.addVideoCourseId;
            this.courseVideoObj.videoList = [];
            this.$refs.upload.submit();
            console.log(this.activeObj.courseId)
            // this.loadingInstance = ElLoading.service({
            //     lock: true,
            //     text: '提交中',
            //     background: 'rgba(0, 0, 0, 0.7)',
            // })
            // console.log(this.fileList)
            // let formdata = new FormData();
            // this.fileList.map((item, index) => {
            //     console.log(item, index)
            //     var okey = 'files'
            //     formdata.append(okey, item.raw)
            // })
            // formdata.append('courseId', this.addVideoCourseId)
            // trainCourseUploadTrainVideos(formdata).then(res => {
            //     if (res.data.code == 1) {
            //         this.loadingInstance.close()
            //         this.$message.success("新增成功");
            //         this.addVideoShow = false;
            //         this.getfamousCourseInfo()
            //         // this.$emit('isTg', true);
            //     } else {
            //         this.loadingInstance.close()
            //         this.$message.error("操作失败")
            //     }
            // })
        },
        changeTree(data) {
            console.log(data);
            for (let key in this.flvPlayerList) {
                this.flvPlayerList[key].pause()
                this.flvPlayerList[key].unload()
                this.flvPlayerList[key].detachMediaElement()
                this.flvPlayerList[key].destroy()
            }
            this.isshow = false;
            this.activeObj = data;
            this.flvPlayerList = {}
            setTimeout(() => {
                this.isshow = true;
                setTimeout(() => {
                    if (this.activeObj.videoList) {
                        this.activeObj.videoList.forEach(e => {
                            var videoElement = document.getElementById(e.videoId);
                            var videoType = e.videoUrl.substring(e.videoUrl.length - 3).toLowerCase()
                            this.flvPlayerList[e.videoId] = flvjs.createPlayer({
                                type: videoType,
                                isLive: true,
                                hasAudio: true,
                                url: e.videoUrl
                            },
                                {
                                    enableWorker: false, //不启用分离线程
                                    enableStashBuffer: false, //关闭IO隐藏缓冲区
                                    reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
                                    autoCleanupSourceBuffer: true //自动清除缓存
                                })
                            this.flvPlayerList[e.videoId].attachMediaElement(videoElement);
                            this.$forceUpdate()
                            this.flvPlayerList[e.videoId].load();
                        })
                    }

                }, 1000)
            })
        },
        submiteditCourseForm() {
            this.$refs["editCourseForm"].validate(valid => {
                if (valid) {
                    trainCourseUpdateChapter({
                        courseId: this.appCourseForm.parentId,
                        name: this.appCourseForm.name,
                    }).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("创建成功");
                            this.editCourseShow = false;
                            this.appCourseForm = {
                                name: '',
                                parentId: ''
                            }
                            this.getfamousCourseInfo()
                        } else {
                            this.$message.error("操作失败")
                        }
                    })
                }
            });
        },
        submitCourseForm() {
            this.$refs["appCourseForm"].validate(valid => {
                if (valid) {
                    trainCourseChapter(this.appCourseForm).then(res => {
                        if (res.data.code == 1) {
                            this.$message.success("创建成功");
                            this.appCourseShow = false;
                            this.appCourseForm = {
                                name: '',
                                parentId: ''
                            }
                            this.getfamousCourseInfo()
                        } else {
                            this.$message.error("操作失败")
                        }
                    })
                }
            });
        },
        append(data) {
            this.appCourseForm = {
                name: '',
                parentId: data.courseId
            }
            if (data.videoList.length != 0) {
                this.$message.error('该层有视频，无法创建下级')
            } else {
                this.appCourseShow = true;
            }

            // famousCourseChapter({
            //     name: '节点111',
            //     parentId: data.courseId
            // }).then(res => {

            // })
            console.log(data);
        },
        addVideo(data) {
            console.log(data);
            this.fileList = [];
            this.ljObj.videoTitle = '';
            this.ljObj.videoUrl = '';

            if (data.childrenList) {
                if (data.childrenList.length == 0) {
                    this.addVideoCourseId = data.courseId
                    this.addVideoShow = true;
                } else {
                    this.$message.error('请在最下一级添加视频')
                }
            } else {
                this.addVideoCourseId = data.courseId
                this.addVideoShow = true;
            }


        },
        editCourse(data) {
            this.appCourseForm = {
                name: data.courseName,
                parentId: data.courseId
            }
            this.editCourseShow = true;
        },
        delCourse(node, data) {
            console.log(node, data);
            trainCourseDel({ courseId: data.courseId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.getfamousCourseInfo()
                } else {
                    this.$message.error("操作失败")
                }
            })
        },
        remove(node, data) {
            console.log(node, data);
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.courseCover != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        // handleRemove(file, uploadFiles) {
        //     console.log(file, uploadFiles)
        //     if (file.response.data) {
        //         this.delFileList.push(file.response.data)
        //     }
        // },
        getfamousCourseInfo() {
            console.log(this.activeObj);
            delete this.activeObj.videoList
            this.isType = 1;
            this.isVideo = 0;
            this.dataSource = [];
            // userList().then(res => {
            //     this.userList = res.data.data;
            trainCourseInfo({ courseId: this.courseId }).then(res => {
                this.form = res.data.data;
                this.dataSource.push(this.form)
                if (this.isLook == 0) {
                    var isz = false;
                    var isj = false;
                    if (this.form.videoList.length != 0) {
                        this.isVideo = 1;
                    } else {
                        this.isVideo = 0;
                    }
                    if (this.form.childrenList) {
                        this.form.childrenList.forEach(j => {
                            if (j.videoList.length != 0) {
                                this.isVideo = 2;
                            }
                            if (j.childrenList) {
                                j.childrenList.forEach(m => {
                                    if (m.videoList.length != 0) {
                                        this.isVideo = 3;
                                    }
                                    isj = true;
                                })
                            } else {
                                isz = true;
                            }

                        })
                    } else {

                    }
                    if (isj == true) {
                        this.isType = 3;
                    } else {
                        if (isz == true) {
                            this.isType = 2;
                        }
                    }
                    console.log(this.isType);

                    // this.dataSource.push({
                    //     id: this.form.courseId,
                    //     label: this.form.courseName,
                    // })
                    console.log(this.dataSource);
                    if (this.activeObj.courseId) {
                        // this.form.forEach(e => {
                        if (this.form.childrenList) {
                            this.form.childrenList.forEach(j => {
                                if (j.childrenList) {
                                    j.childrenList.forEach(m => {
                                        if (m.courseId == this.activeObj.courseId) {
                                            this.changeTree(m)
                                            // this.activeObj = m;
                                        }
                                        if (m.videoList && m.videoList.length != 0) {

                                        }
                                    })
                                }
                                if (j.videoList && j.videoList.length != 0) {

                                }
                                if (j.courseId == this.activeObj.courseId) {
                                    this.changeTree(j)
                                    // this.activeObj = j;
                                }
                            })
                        }

                        if (this.form.courseId == this.activeObj.courseId) {
                            this.changeTree(this.form)
                            // this.activeObj = this.form;
                        }
                        if (this.activeObj.videoList) {

                        } else {
                            this.changeTree(this.dataSource[0])
                            // this.activeObj = this.dataSource[0]
                        }
                        // })
                        this.$nextTick(() => {
                            // selectId：绑定的 node-key
                            this.$refs.tree.setCurrentKey(this.activeObj.courseId);
                        });
                    } else {
                        this.$nextTick(() => {
                            // selectId：绑定的 node-key
                            this.$refs.tree.setCurrentKey(this.dataSource[0].courseId);
                            this.changeTree(this.dataSource[0])
                            // this.activeObj = this.dataSource[0]
                        });
                    }
                } else if (this.isLook == 1) {
                    if (this.form.videoList.length != 0) {
                        this.$nextTick(() => {
                            this.$refs.tree.setCurrentKey(this.dataSource[0].courseId);
                        });
                        this.changeTree(this.dataSource[0])
                        // this.activeObj = this.dataSource[0]
                    } else {
                        if (this.form.childrenList) {
                            try {
                                this.form.childrenList.forEach(j => {
                                    if (j.childrenList) {
                                        j.childrenList.forEach(m => {
                                            if (m.courseId == this.activeObj.courseId) {
                                                this.changeTree(m)
                                                // this.activeObj = m;
                                            }
                                            if (m.videoList && m.videoList.length != 0) {
                                                console.log(m.courseId);
                                                this.changeTree(m)
                                                // this.activeObj = m;
                                                this.$nextTick(() => {
                                                    // selectId：绑定的 node-key
                                                    this.$refs.tree.setCurrentKey(m.courseId);
                                                });
                                                throw new Error()
                                            }
                                        })
                                    }
                                    if (j.videoList && j.videoList.length != 0) {
                                        console.log(j.courseId);
                                        this.changeTree(j)
                                        // this.activeObj = j;
                                        this.$nextTick(() => {
                                            // selectId：绑定的 node-key
                                            this.$refs.tree.setCurrentKey(j.courseId);
                                        });
                                        throw new Error()
                                    }
                                })
                            } catch (error) {
                                console.log(error);
                            }

                        }
                    }
                }

            })
            // })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)
        },
        handleXAChange(file) {
            console.log(file);
        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || rawFile.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || rawFile.type == 'application/msword')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx,.doc,.docx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getQueryDict() {
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.courseCover = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },

        // 取消按钮
        cancel() {
            if (this.isTranDetail == 1) {
                this.replace('/train')
            } else {
                if (this.isLook == 0) {
                    this.replace('/traCourse')
                } else {
                    this.replace('/traCourse')
                }
            }

            // this.replace('/famousCourse')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.tstih {
    position: relative;
    padding: 15px;
}

.start {
    display: none;
}

.tstih:hover .start {
    display: block;
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.custtoo /deep/ .el-tree-node__label {
    flex: 1;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.loading-overlay {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

::v-deep .el-progress__text {
    font-size: 24px !important;
    color: rgb(30, 160, 225) !important;
}

::v-deep .el-progress-circle {
    width: 150px !important;
    height: 150px !important;
}

.tips_css {
    font-size: 20px;
    color: rgb(30, 160, 225)
}</style>