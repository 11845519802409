<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel" v-if="isPrincipalStudio == 1"><a>名校长工作室统计</a></el-breadcrumb-item>
                <el-breadcrumb-item @click="cancel" v-if="isMsgzsStatistics == 1"><a>名师工作室统计</a></el-breadcrumb-item>
                <el-breadcrumb-item @click="cancel" v-if="isJkyStatistics == 1"><a>教科研统计</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="isMsgzsStatistics != 1 && isJkyStatistics != 1 && isPrincipalStudio != 1"
                    @click="cancel"><a>课题引领</a></el-breadcrumb-item>
                <el-breadcrumb-item>课题引领详情</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 140px);overflow: auto;">
            <el-descriptions :column="3" class="detailFF" title="基本信息">
                <el-descriptions-item label="课题名称">{{ form.topicName }}</el-descriptions-item>
                <el-descriptions-item label="备课性质">
                    <span v-if="form.topicNature == 1">普通</span>
                    <span v-if="form.topicNature == 2">名师工作室</span>
                    <span v-if="form.topicNature == 3">名校长工作室</span>
                    <span v-if="form.topicNature == 4">群组任务</span>
                </el-descriptions-item>
                <el-descriptions-item label="工作室" v-if="form.groupName">{{ form.groupName }}</el-descriptions-item>
                <el-descriptions-item v-if="form.topicNature && form.topicNature == '1' && form.periodName" label="学段">{{ form.periodName
                }}</el-descriptions-item>
                <el-descriptions-item v-if="form.topicNature && form.topicNature == '1' && form.gradeName" label="年级">{{ form.gradeName
                }}</el-descriptions-item>
                <el-descriptions-item v-if="form.topicNature && form.topicNature == '1' && form.subjectName" label="学科">{{ form.subjectName
                }}</el-descriptions-item>
                <el-descriptions-item label="课题级别">{{ form.topicLevelName }}</el-descriptions-item>
                <el-descriptions-item label="课题单位">{{ form.orgTxt }}</el-descriptions-item>
                <el-descriptions-item label="展示类型">
                    <span v-if="form.topicType == 0">文本展示</span>
                    <span v-if="form.topicType == 1">图片展示</span>
                </el-descriptions-item>
                <el-descriptions-item label="课题分类">{{ form.topicClassifyName }}</el-descriptions-item>
                <el-descriptions-item label="课题负责人">{{ form.leaderName }}</el-descriptions-item>
                <el-descriptions-item label="参与总人数">{{ form.totalCount }}</el-descriptions-item>
                <el-descriptions-item label="申报时间">{{ timestampToTime(form.applyBeginTime) }}至{{ timestampToTime(form.applyEndTime) }}</el-descriptions-item>
                <el-descriptions-item label="开题时间">{{ timestampToTime(form.beginTime) }}至{{ timestampToTime(form.endTime) }}</el-descriptions-item>
            </el-descriptions>
            <el-form ref="form" :model="form" label-width="68px">
                <el-row v-if="form.recommend && form.topicType == 0">
                    <el-col :span="24">
                        <el-form-item label="课题介绍" prop="judgesUsersList">
                            {{ form.recommend }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.topicType == 0 && form.studyAim">
                    <el-col :span="24">
                        <el-form-item label="研究目的" prop="studyAim">
                            {{ form.studyAim }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.topicType == 0 && form.studyMethod">
                    <el-col :span="24">
                        <el-form-item label="研究方法" prop="studyMethod">
                            {{ form.studyMethod }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.remarks">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="其他" prop="remarks">
                            {{ form.remarks }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-col :span="12" v-if="form.scaleList && form.scaleList.length != 0">
                    <el-form-item label="听评量表" prop="scaleList">
                        <div v-for="item in form.scaleList" style="color: #409efe;width: 100%;">
                            <div class="cursor" @click="preview(item)">{{ item.title }}</div>
                        </div>
                    </el-form-item>
                </el-col>
                <el-row v-if="form.fileList && form.fileList.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="附件" prop="">
                            <div @click="xz(item)" v-for="item in form.fileList" class="mb10 cursor">
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.topicType == 1 && form.imgUrl">
                    <el-col :span="24">
                        <el-form-item label="导入图片" prop="imgUrl">
                            <div class="avatar-uploader">
                                <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar" />
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <!-- <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div> -->
        <el-dialog :title="'创建课题分类'" v-model="topicClassifyShow" width="60%">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="名称" prop="orgName">
                        <el-input v-model="classifyName" placeholder="最多16个字" maxlength="16" />
                    </el-form-item>
                </el-col>
            </el-row>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitTopicClassifyForm">确 定</el-button>
                    <el-button @click="topicClassifyShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div class="mb30" v-for="(item, index) in previewData.questionList">
                <div class="mb15" v-if="item.type != 3">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                    }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                            item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                                : ''
                        }}]</span><span v-if="item.type == 4 && item.totalScore"
                            style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                item.totalScore }})</span>
                        <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                            item.totalScore }})</span>
                    </h3>
                </div>
                <div class="mb15" v-if="item.type == 3">
                    <div class="mb15">
                        <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                        }}.</span>{{ item.questionName }}
                            <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                    '文字内容'
                                    : ''
                            }}]</span>
                            <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                                item.totalScore }})</span>
                        </h3>
                    </div>
                    <div>

                        <el-input-number size="small" step-strictly disabled :min="0" :max="100" />
                    </div>
                </div>
                <div v-if="item.type == 1">
                    <el-radio-group disabled class="ml-4">
                        <el-radio label="1" size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span></el-radio>
                    </el-radio-group>
                </div>
                <div v-if="item.type == 2">
                    <el-checkbox-group disabled class="ml-4">
                        <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="item.type == 4" style="width: 100%;">
                    <el-input style="width: 100%;" disabled :rows="2" type="textarea" placeholder="" />
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";

import { queryDict, userList, listHeadmasterGroup, listTeacherGroup } from "@/api/admin/public";
import { orgListTypeOrg, orgListSchoolOrg } from "@/api/admin/system";
import { rateScaleInfo } from "@/api/admin/evaluation";
import { leadAddClassify, leadCreate, leadDeleteFile, leadUpdate, leadInfo } from "@/api/admin/lead";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            timestampToTime,
            topicClassifyShow: false,
            classifyName: '',
            fileList: [],
            toParentsMap: {

            },
            isShow: false,
            oldCenter: [],
            topicId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            topicClassifyList: [],
            orgList: [],
            topicLevelList: [
                { dictKey: '市级', dictValue: 1 },
                { dictKey: '区县', dictValue: 2 },
                { dictKey: '学校', dictValue: 3 },
            ],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                topicName: '',
                topicLevel: '',
                topicType: 0,
                topicClassifyKey: '',
                leader: '',
                totalCount: 10,
                recommend: '',
                checkUsersList: [],
                orgIdsList: [],
                trainLevel: '',
                beginTime: '',
                endTime: '',
                studyAim: '',
                studyMethod: '',
                remarks: '',
                fileUrls: '',
                imgUrl: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: ''
            },
            // 表单校验
            rules: {
                periodKey: [
                    { required: true, message: "请选择学段", trigger: "change" },
                ],
                gradeKey: [
                    { required: true, message: "请选择年级", trigger: "change" },
                ],
                subjectKey: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                checkUsersList: [
                    { required: true, message: "请选择审核人员", trigger: "change" },
                ],
                orgIdsList: [
                    { required: true, message: "请选择课题单位", trigger: "change" },
                ],
                topicName: [
                    { required: true, message: "请输入课题名称", trigger: "blur" },
                ],
                topicClassifyKey: [
                    { required: true, message: "请选择课题分类", trigger: "change" },
                ],
                topicLevel: [
                    { required: true, message: "请选择课题级别", trigger: "change" },
                ],
                totalCount: [
                    { required: true, message: "请选择参与总人数", trigger: "change" },
                ],
                topicType: [
                    { required: true, message: "请选择课题类型", trigger: "change" },
                ],
                trainLevel: [
                    { required: true, message: "请选择培训级别", trigger: "change" },
                ],
                leader: [
                    { required: true, message: "请选择课题负责人", trigger: "change" },
                ],
                formTime: [
                    { required: true, validator: this.checkValidator }
                ],
            },
            delFileList: [],
            countyList: [],
            schoolList: [],
            groupList: [],
            previewData: {},
            previewShow: false,
            isMsgzsStatistics: 0,
            isJkyStatistics: 0,
            isPrincipalStudio: 0,
        };
    },
    created() {
        this.getOrgListTypeOrg();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.topicId) {
            this.topicId = this.$route.query.topicId
            console.log(this.form)
            this.getlessonInfo()
        } else {
            this.isShow = true
        }
        if (this.$route.query.isJkyStatistics) {
            this.isJkyStatistics = this.$route.query.isJkyStatistics
        }
        if (this.$route.query.isMsgzsStatistics) {
            this.isMsgzsStatistics = this.$route.query.isMsgzsStatistics
        }
        if (this.$route.query.isPrincipalStudio) {
            this.isPrincipalStudio = this.$route.query.isPrincipalStudio
        }
    },
    unmounted() {
        if (this.topicId) {
            try {
                console.log(332)
                this.fileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        leadDeleteFile({ fileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.fileList.forEach(e => {
                    leadDeleteFile({ fileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }

        }
    },
    computed: {
    },
    methods: {
        preview(item) {
            console.log(item)
            rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                this.previewData = res.data.data;
                this.previewShow = true;
            })
        },
        changeLessonNature(e) {
            console.log(e);
            this.groupList = [];
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            if (e == 2) {
                listTeacherGroup().then(res => {
                    res.data.data.forEach(j => {
                        if (this.form.groupId == j.groupId) {
                            this.form.groupName = j.groupName
                        }
                        j.memberList.forEach(m => {
                            if (m.memberRole == 0 || m.memberRole == 1) {
                                if (m.memberUserId == userId) {
                                    this.groupList.push(j)
                                    // throw new Error();
                                }
                            }
                        })
                    })
                })
            } else if (e == 3) {
                listHeadmasterGroup().then(res => {
                    res.data.data.forEach(j => {
                        if (this.form.groupId == j.groupId) {
                            this.form.groupName = j.groupName
                        }
                        j.memberList.forEach(m => {
                            if (m.memberRole == 0 || m.memberRole == 1) {
                                if (m.memberUserId == userId) {
                                    this.groupList.push(j)
                                    // throw new Error();
                                }
                            }
                        })
                    })
                })
            }
        },
        xz(item) {
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
            // download(item.fileUrl, item.fileName)
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.imgUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        openLeadClassify() {
            this.classifyName = ''
            this.topicClassifyShow = true;
        },
        submitTopicClassifyForm() {
            leadAddClassify({ classifyName: this.classifyName }).then(res => {
                if (res.data.code == 1) {
                    this.getQueryDict();
                    this.$message.success('添加成功')
                    this.topicClassifyShow = false;
                } else {
                    this.$message.error('添加失败')
                }
            })
        },
        getGeocoder(e) {
            console.log(e);
            this.form.lat = e.center[1];
            this.form.lng = e.center[0];
            this.form.signInAddress = e.formattedAddress;
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getlessonInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                queryDict({ code: 'topic_level' }).then(resb => {
                    this.topicLevelList = resb.data.data;
                    leadInfo({ topicId: this.topicId }).then(res => {
                        this.changeLessonNature(res.data.data.topicNature)

                        var orgTxt = []
                        res.data.data.orgList.forEach(e => {
                            orgTxt.push(e.orgName)
                        })
                        res.data.data.orgTxt = orgTxt.join('、')
                        res.data.data.leaderName = ''
                        res.data.data.leaderList.forEach(e => {
                            if (res.data.data.leader == e.userId) {
                                res.data.data.leaderName = e.trueName
                            }
                        })
                        var checkUserTxt = []
                        res.data.data.checkUserList.forEach(e => {
                            checkUserTxt.push(e.trueName)
                        })
                        res.data.data.checkUserTxt = checkUserTxt.join('、')
                        this.form = res.data.data;
                        this.form.checkUsersList = this.form.checkUsers.split(',')
                        this.form.topicLevel = this.form.topicLevel;
                        this.form.orgIdsList = res.data.data.orgIds.split(',')
                        this.topicLevelList.forEach(e => {
                            if (e.dictKey == this.form.topicLevel) {
                                this.form.topicLevelName = e.dictValue
                            }
                        })
                        orgListTypeOrg({ orgType: this.form.topicLevel }).then(res => {
                            this.orgList = res.data.data;
                        })
                        this.periodList.forEach(j => {
                            if (this.form.periodKey == j.dictKey) {
                                this.gradeList = j.childrenList;
                            }
                        })
                        this.periodList.forEach(e => {
                            if (this.form.periodKey == e.dictKey) {
                                e.childrenList.forEach(j => {
                                    if (this.form.gradeKey == j.dictKey) {
                                        this.subjectList = j.childrenList;
                                    }
                                })
                            }
                        })
                        this.form.fileList.forEach(e => {
                            this.fileList.push({
                                name: e.fileName,
                                fileId: e.fileId,
                                url: e.fileUrl,
                                response: {
                                    data: e.fileUrl
                                }
                            })
                        })
                        this.isShow = true;
                    })
                })

            })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        startSignInDateConfirm(e) {
            if (e > this.form.signInEndTime) {
                this.form.signInEndTime = '';
            }
        },
        startSignOutDateConfirm(e) {
            if (e > this.form.signOutEndTime) {
                this.form.signOutEndTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        endSignInDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signInBeginTime) {
                this.form.signInBeginTime = '';
            }
        },
        endSignOutDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signOutBeginTime) {
                this.form.signOutBeginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            this.form.orgIdsList = [];
            orgListTypeOrg({ orgType: this.form.topicLevel }).then(res => {
                this.orgList = res.data.data;
            })
        },
        getQueryDict() {
            queryDict({ code: 'topic_classify' }).then(res => {
                this.topicClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            // queryDict({ code: 'topic_level' }).then(res => {
            //     this.lessonLevelList = res.data.data;
            // })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            var fileList = [];
            this.fileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.form.checkUsers = this.form.checkUsersList.toString()
            this.form.orgIds = this.form.orgIdsList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    obj.fileUrls = fileList.toString();
                    delete obj.checkUsersList
                    delete obj.orgIdsList
                    if (this.topicId) {
                        leadUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    leadDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("编辑成功");
                                this.replace('/lead', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        leadCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    leadDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("新增成功");
                                this.replace('/lead', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            if (this.isJkyStatistics == 1) {
                this.replace('/jkyStatistics')
            } else if (this.isMsgzsStatistics == 1) {
                this.replace('/msgzsStatistics')
            } else if (this.isPrincipalStudio == 1) {
                this.replace('/principalStudio')
            } else {
                this.replace('/lead')
            }
            // this.jAFileList = [];

            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}</style>