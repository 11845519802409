<template>
    <div class="app-container" style="padding-left: 0;">
        <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
            @submit.native.prevent="handleQuery">
            <el-row class=" mb15">
                <el-col :span="6" class="mb0">
                    <el-form-item label="所属组织">
                        <el-tree-select @change="getList" clearable
                            :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                            v-model="queryParams.orgId" default-expand-all :data="treeData" check-strictly
                            :render-after-expand="true" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" class="mb0">
                    <el-form-item label="时间">
                        <el-date-picker style="width: 100%;" :disabled-date="disabledDate" v-model="queryParams.rzTime"
                            format="YYYY/MM/DD" value-format="x" clearable @change="getList" type="daterange"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="5" class="mb0">
                    <el-radio-group @change="changeTableType" v-model="queryParams.tableType" size="mini">
                        <el-radio-button label="按个人分" />
                        <el-radio-button label="按组织分" v-has-btn="'1,2,3'" />
                    </el-radio-group>
                </el-col>
                <el-col :span="5" class="mr0 mb0">
                    <el-form-item class="pull-right">
                        <el-button v-has-btn="'1,2,3'" style="" @click="changeZxtType" class="mb0 mr0 pull-right"
                            type="primary" size="mini">柱状图</el-button>
                        <el-button @click="handleExport" type="primary" size="mini">导出</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="bre9e mt15">
            <el-table header-cell-class-name="bgf2" show-overflow-tooltip height="calc(100vh - 250px)" border
                v-loading="loading" :data="dataList" highlight-current-row>
                <!-- <el-table-column type="selection" width="55" /> -->
                <el-table-column type="index" label="序号" width="60" />
                <el-table-column v-if="queryParams.tableType == '按个人分'" width="350" label="姓名" prop="trueName">
                    <template #default="scope">
                        <div :title="scope.row.trueName" class="text-one">
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="queryParams.tableType == '按个人分' ? '所属组织' : '组织名称'" prop="orgName">
                    <template #default="scope">
                        <div :title="scope.row.orgName" class="text-one">
                            {{ scope.row.orgName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="90" label="总培训数" prop="count">
                    <template #default="scope">
                        <div @click="toBk(scope.row)" style="color: #409efe;" class="text-one cursor">{{ scope.row.count
                        }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog :title="'自定义柱状图'" v-model="zxtTypeOpen" width="60%">
            <el-form-item label="按所属组织统计" label-width="120px">
                <el-tree-select @change="changeZxtOrg" clearable multiple filterable style="width: 100%;"
                    :props="{ value: 'orgId', label: 'orgName', children: 'childrenList' }"
                    v-model="zxtQueryParams.orgIdList" default-expand-all :data="treeData" check-strictly
                    :render-after-expand="true" />
            </el-form-item>
            <el-form-item label="按所选人员统计" label-width="120px">
                <el-select @change="changeZxtUser" style="width: 100%;" multiple filterable
                    v-model="zxtQueryParams.userIdList" placeholder="请选择人员">
                    <el-option v-for="item in userList" :key="item.userId" :label="item.trueName" :value="item.userId" />
                </el-select>
            </el-form-item>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitXtTyp">确 定</el-button>
                    <el-button @click="zxtTypeOpen = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="'师资培训'" v-model="bkOpen" width="850px">
            <el-table show-overflow-tooltip @current-change="handleCurrentChange" class="" height="calc(100vh - 500px)"
                border v-loading="loading" :data="selectObj.trainList" highlight-current-row>
                <!-- <el-table-column type="selection" width="55" /> -->
                <el-table-column type="index" label="序号" width="60" />
                <el-table-column width="" :label="'培训任务标题'" prop="title">
                    <template #default="scope">
                        <div :title="scope.row.title" style="color: #409efe;" class="text-one cursor"
                            @click="goDetail(scope.row)">
                            {{ scope.row.title }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="90" label="等级" prop="levelName">
                    <template #default="scope">
                        <div class="">
                            {{ scope.row.levelName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="300" label="任务时间" prop="applyTime">
                    <template #default="scope">
                        <div v-if="queryParams.rzTime && queryParams.rzTime.length != 0">
                            <span :style="scope.row.beginTime < queryParams.rzTime[0] ? 'color:red' : ''">{{
                                timestampToTime(scope.row.beginTime) }}</span> - <span
                                :style="scope.row.endTime > queryParams.rzTime[1] ? 'color:red' : ''">{{
                                    timestampToTime(scope.row.endTime) }}</span>
                        </div>
                        <div v-else>
                            {{ timestampToTime(scope.row.beginTime) }} - {{ timestampToTime(scope.row.endTime) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="90" label="打卡情况" prop="dkqk" v-if="cativeType == '线下培训'">
                    <template #default="scope">
                        <div @click="goDkqkDetail(scope.row)" style="color: #409efe;" class="text-one cursor">
                            {{ scope.row.signCount }} / {{ scope.row.totleCount }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="90" label="观看时长(min)" prop="gksz" v-if="cativeType == '线上录播'">
                    <template #default="scope">
                        <div class="">
                            {{ Math.round(scope.row.viewTime / 60) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="90" label="学时" prop="xs" v-if="cativeType == '线上录播'">
                    <template #default="scope">
                        <div class="">
                            {{ scope.row.classHour }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="90" label="调查问卷" prop="rwlb">
                    <template #default="scope">
                        <el-popover v-if="scope.row.scaleList" placement="bottom" :width="200" trigger="click">
                            <div @click="toScale(item)" style="color: #409efe;" class="cursor text-one"
                                :title="item.info.title" v-for="item in scope.row.scaleList">{{ item.info.title }}</div>
                            <template #reference>
                                <div style="color: #409efe;" class="cursor">
                                    {{ scope.row.scaleList.length }}
                                </div>
                            </template>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog :title="'打卡情况'" v-model="dkqkOpen" width="650px">
            <el-table :span-method="objectSpanMethod" show-overflow-tooltip class="" height="calc(100vh - 500px)" border
                :data="dkqkList" highlight-current-row>
                <!-- <el-table-column type="selection" width="55" /> -->
                <el-table-column width="" :label="'姓名'" prop="title">
                    <template #default="scope">
                        <div class="text-one">
                            {{ scope.row.trueName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="签到时间" prop="levelName">
                    <template #default="scope">
                        <div class="">
                            {{ timestampToTime(scope.row.signInTime) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="签退时间" prop="levelName">
                    <template #default="scope">
                        <div class="">
                            {{ timestampToTime(scope.row.signOutTime) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="" label="状态" prop="signStatus">
                    <template #default="scope">
                        <div class="" v-if="scope.row.signStatus == 1">
                            异常打卡
                        </div>
                        <div class="" v-if="scope.row.signStatus == 2">
                            异常打卡
                        </div>
                        <div class="" v-if="scope.row.signStatus == 3">
                            正常打卡
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog v-if="zxtOpen" :title="'柱状图'" v-model="zxtOpen" width="70%">
            <div id="grbk" style="width: 100%;height:50vh;">
            </div>
        </el-dialog>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <previewData :isShowHidden="false" :disabled="true" ref="editData" v-if="previewShow" :previewData="previewData" :isHide="isHide">
            </previewData>
        </el-dialog>
    </div>
</template>

<script>
import { statsTrainSign, statsTopic, statsRace, statsActivity, statsTrain, statsPrincipalStudio, statsTeacherStudio } from "@/api/admin/statistics";
import { orgListTree } from "@/api/admin/system";
import table2excel from 'js-table2excel';
import { timestampToTime } from "@/utils/date";
import { queryDict } from "@/api/admin/public";
import previewData from "@/components/previewData.vue";
import echarts from "echarts";

export default {
    name: "teachTrain",
    components: {
        previewData
    },
    data() {
        return {
            previewData: {},
            previewShow: false,
            isHide: false,
            dkqkOpen: false,
            dkqkList: [],
            cativeType: '线下培训',
            zxtTypeOpen: false,
            zxtOpen: false,
            bkOpen: false,
            statusList: [
                { name: '未发布', value: 0 },
                { name: '已发布', value: 1 },
                { name: '隐藏', value: 2 },
            ],
            treeData: [],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 查询参数
            queryParams: {
                tableType: '按个人分',
                orgId: '',
                rzTime: [],
            },
            zxtQueryParams: {
                orgIdList: [],
                userIdList: [],
            },
            selectObj: [],
            grbkOption: {
                color: ['#3398DB'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '总数',
                        type: 'bar',
                        barMaxWidth: 30,
                        data: [10, 52, 200, 334, 390, 330, 220]
                    }
                ]
            },
            userList: [],
            lookType: null,
            lessonLevelList: []
        };
    },
    created() {
        this.getTopLev()
        this.getOrgListTree();
        this.getList();
    },
    mounted() {
        // this.getGrbkOption()
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        toScale(item) {
            this.isHide = false;
            setTimeout(() => {
                console.log(item);
                if (item.info.isHide == 1) {
                    this.isHide = true;
                }
                this.previewData = item.info;
                this.previewData.questionList.forEach(e => {
                    if (e.type == 2 && e.questionAnswer) {
                        e.questionAnswer = e.questionAnswer.split(',');
                    } else {
                        // e.questionAnswer = '';
                    }
                })
                this.previewShow = true;
            })
        },
        objectSpanMethod(e) {
            // console.log(e);
            if (e.columnIndex === 0) {
                if (e.row.col != 0) {
                    return {
                        rowspan: e.row.col,
                        colspan: 1,
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }
        },
        getTopLev() {
            queryDict({ code: 'topic_level' }).then(resb => {
                this.lessonLevelList = resb.data.data;
            })
        },
        changeTableType() {
            this.getList();
        },
        changeCativeType(type) {
            console.log(type);
            this.cativeType = type;
            this.queryParams = {
                tableType: '按个人分',
                orgId: '',
                rzTime: [],
            };
            this.zxtQueryParams = {
                orgIdList: [],
                userIdList: [],
            };
            // this.getGrbkOption();
            this.getList();
        },
        toBk(row, type) {
            console.log(type);
            row.trainList.forEach(j => {
                this.lessonLevelList.forEach(e => {
                    if (e.dictKey == j.trainLevel) {
                        j.levelName = e.dictValue
                    }
                })
            })
            this.lookType = type;
            this.selectObj = row;
            this.bkOpen = true;
            console.log(row);
        },
        changeTime() {
            console.log(this.queryParams.rzTime);
        },
        changeZxtType() {
            this.zxtTypeOpen = true;
        },
        submitXtTyp() {
            if (this.zxtQueryParams.orgIdList.length == 0 && this.zxtQueryParams.userIdList.length == 0) {
                this.getGrbkOption()
            }
            if (this.zxtQueryParams.orgIdList.length != 0) {
                this.getGrbkOption()
            }
            if (this.zxtQueryParams.userIdList.length != 0) {
                this.getUserGrbkOption()
            }
            this.zxtTypeOpen = false;
        },
        getOrgListTree() {
            orgListTree().then(res => {
                this.treeData = res.data.data;
            })
        },
        handleExport() {
            var timestamp = timestampToTime(Date.parse(new Date()));
            const tableDatas = JSON.parse(JSON.stringify(this.dataList))//这里面填写你接口的数据
            const datas = tableDatas
            if (this.queryParams.tableType != '按个人分') {
                const column = [
                    {
                        title: '组织名称',
                        key: 'orgName',
                        type: 'text',
                    },
                    {
                        title: '总培训数',
                        key: 'count',
                        type: 'text',
                    }
                ]
                table2excel(column, datas, this.cativeType + '组织统计' + timestamp)
            } else {
                const column = [
                    {
                        title: '姓名',
                        key: 'trueName',
                        type: 'text',
                    },
                    {
                        title: '所属组织',
                        key: 'orgName',
                        type: 'text',
                    },
                    {
                        title: '总培训数',
                        key: 'count',
                        type: 'text',
                    }
                ]
                table2excel(column, datas, this.cativeType + '个人统计' + timestamp)
            }
        },
        getGrbkOption() {
            var type = 2;
            if (this.cativeType == '线下培训') {
                type = 2;
            } else if (this.cativeType == '线上录播') {
                type = 1;
            } else if (this.cativeType == '线上直播') {
                type = 0;
            }
            statsTrain({ type: type }).then(res => {
                this.grbkOption.xAxis[0].data = []
                this.grbkOption.series[0].data = []
                if (this.zxtQueryParams.orgIdList.length != 0) {
                    res.data.data = res.data.data.filter(itemA => {
                        return this.zxtQueryParams.orgIdList.indexOf(itemA.orgId) != -1;
                    })
                }
                res.data.data.forEach(e => {
                    this.grbkOption.xAxis[0].data.push(e.orgName)
                    this.grbkOption.series[0].data.push(e.amount)
                })
                this.zxtOpen = true;
                setTimeout(() => {
                    let myChart3 = echarts.init(document.getElementById('grbk'))
                    myChart3.setOption(this.grbkOption, true)
                });
            })

        },
        getUserGrbkOption() {
            this.grbkOption.xAxis[0].data = []
            this.grbkOption.series[0].data = []
            var userOption = JSON.parse(JSON.stringify(this.dataList));
            userOption = userOption.filter(itemA => {
                return this.zxtQueryParams.userIdList.indexOf(itemA.userId) != -1;
            })
            userOption.forEach(e => {
                this.grbkOption.xAxis[0].data.push(e.trueName)
                this.grbkOption.series[0].data.push(e.aidAmount + e.guideAmount + e.leadAmount)
            })
            console.log(this.grbkOption);
            this.zxtOpen = true;
            setTimeout(() => {
                let myChart3 = echarts.init(document.getElementById('grbk'))
                myChart3.setOption(this.grbkOption, true)
            });
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.dataList = [];
            this.userList = []
            var type = 2;
            if (this.cativeType == '线下培训') {
                type = 2;
            } else if (this.cativeType == '线上录播') {
                type = 1;
            } else if (this.cativeType == '线上直播') {
                type = 0;
            }
            var userId = JSON.parse(this.$storage.get("userInfo")).userId

            statsTrain({ type: type }).then(res => {
                if (this.queryParams.orgId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.orgId == this.queryParams.orgId
                    })
                }
                res.data.data.forEach(e => {
                    if (this.$storage.get("role") != 1 && this.$storage.get("role") != 2 && this.$storage.get("role") != 3) {
                        e.userList = e.userList.filter(itemA => {
                            return itemA.userId == userId
                        })
                    }
                    e.userList.forEach(j => {
                        if (this.queryParams.rzTime && this.queryParams.rzTime.length != 0) {
                            j.trainList = j.trainList.filter(itemA => {
                                return ((itemA.beginTime >= this.queryParams.rzTime[0]) && (itemA.beginTime <= this.queryParams.rzTime[1])) || ((itemA.endTime >= this.queryParams.rzTime[0]) && (itemA.endTime <= this.queryParams.rzTime[1]))
                            })
                        }
                        j.orgName = e.orgName;
                        j.orgId = e.orgId;
                        j.count = j.trainList.length
                        if (this.queryParams.tableType == '按个人分') {
                            this.dataList.push(j)
                        }
                        this.userList.push(j)
                    })
                })
                if (this.queryParams.tableType == '按组织分') {
                    res.data.data.forEach(e => {
                        e.trainList = [];
                        e.userList.forEach(j => {
                            e.trainList = [...e.trainList, ...j.trainList]
                        })
                        let hash = {};
                        e.trainList = e.trainList.reduceRight((item, next) => {
                            hash[next.trainId] ? '' : hash[next.trainId] = true && item.push(next);
                            return item
                        }, []);
                        e.count = e.trainList.length
                        this.dataList.push(e)
                    })
                }
                console.log(this.dataList);
                this.loading = false;
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/trainDetail",
                query: {
                    trainId: row.trainId,
                    isSzpxStatistics: 1,
                }
            });
        },
        goDkqkDetail(row) {
            this.dkqkList = [];
            console.log(row);
            var userId = ''
            if (this.$storage.get("role") == 6) {
                userId = JSON.parse(this.$storage.get("userInfo")).userId
            } else {
                userId = ''
            }
            statsTrainSign({ trainId: row.trainId, userId: userId }).then(res => {
                res.data.data.forEach(e => {
                    if (e.signList && e.signList.length != 0) {
                        e.signList.forEach((j, n) => {
                            if (n == 0) {
                                this.dkqkList.push({ trueName: e.trueName, signStatus: j.signStatus, signInTime: j.signInTime, signOutTime: j.signOutTime, col: e.signList.length })
                            } else {
                                this.dkqkList.push({ trueName: e.trueName, signStatus: j.signStatus, signInTime: j.signInTime, signOutTime: j.signOutTime, col: 0 })
                            }
                        })
                    } else {
                        this.dkqkList.push({ trueName: e.trueName, signStatus: '', signInTime: '', signOutTime: '', col: 1 })
                    }

                })
                this.dkqkOpen = true;
            })
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        changeZxtOrg() {
            this.zxtQueryParams.userIdList = [];
        },
        changeZxtUser() {
            this.zxtQueryParams.orgIdList = [];
        },
        disabledDate(current) {
            return current && current > Number(new Date())
        },

    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}
</style>