<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>教程库管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!classId">新增课本</el-breadcrumb-item>
                <el-breadcrumb-item v-if="classId">编辑课本</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课本名称" prop="className">
                            <el-input v-model="form.className" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="版本" prop="versionKey">
                            <el-select style="width: 100%;" v-model="form.versionKey" placeholder="请选择组织性质">
                                <el-option v-for="item in versionKeyList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey">
                                    <span style="float: left">{{ item.dictValue }}</span>
                                    <span @click.stop="delFx(item)" style="float: right; color: #8492a6; font-size: 13px">
                                        <el-icon>
                                            <Delete />
                                        </el-icon>
                                    </span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-icon @click="openLeadClassify" class="ml15 cursor" :size="30">
                            <CirclePlus />
                        </el-icon>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学制" prop="studyIsm">
                            <el-radio-group v-model="form.studyIsm">
                                <el-radio :label="'六三制'">六三制</el-radio>
                                <el-radio :label="'五四制'">五四制</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="form.type == 0 ? '内容' : '书本封面'" prop="coverUrl">
                            <el-upload class="avatar-uploader"
                                action="https://www.jingzhunjy.com/businessservice/classLibrary/uploadCover"
                                :show-file-list="false" :on-success="handleAvatarSuccess" :headers="{ token }"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="form.coverUrl" :src="form.coverUrl" class="avatar" style="position:absolute" />
                                <el-icon class="avatar-uploader-icon" style="font-size:100px;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>

                    <el-col :span="8">
                        <el-form-item label="学段" prop="periodKey">
                            <el-select @change="changePeriod" v-model="form.periodKey" placeholder="请选择学段">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="年级" prop="gradeKey">
                            <el-select @change="changeGrade" v-model="form.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="学科" prop="subjectKey">
                            <el-select v-model="form.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item label="版本" prop="versionKey">
                            <el-select style="width: 100%;" v-model="form.versionKey" placeholder="请选择版本">
                                <el-option v-for="item in versionKeyList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row> -->

            </el-form>
        </el-card>
        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
        <el-dialog :title="'创建版本'" v-model="openVersionKeyShow" width="60%">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="名称" prop="orgName">
                        <el-input v-model="classifyName" placeholder="最多16个字" maxlength="16" />
                    </el-form-item>
                </el-col>
            </el-row>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submiVersionKeyShowForm">确 定</el-button>
                    <el-button @click="openVersionKeyShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ElMessageBox } from 'element-plus'
import { famousCourseCreate, famousCourseInfo, famousCourseUpdate } from "@/api/admin/famousCourse"
import { classLibraryCreate, classLibraryUpdate, classLibraryAddClassify } from "@/api/admin/classLibrary";
import { queryDict, userList, deleteDict } from "@/api/admin/public";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            token: this.$storage.get("token"),
            classId: '',
            jAFileList: [],
            xAFileList: [],
            kJFileList: [],
            xTFileList: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            versionKeyList: [],
            userList: [],
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                className: '',
                unitName: '',
                versionKey: '',
                studyIsm: '六三制',
                coverUrl: '',
            },
            // 表单校验
            rules: {
                coverUrl: [
                    { required: true, validator: this.checkCoverValidator }
                ],
                studyIsm:[
                    { required: true, message: "请选择学制", trigger: "change" },
                ],
                periodKey: [
                    { required: true, message: "请选择学段", trigger: "change" },
                ],
                gradeKey: [
                    { required: true, message: "请选择年级", trigger: "change" },
                ],
                subjectKey: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                versionKey: [
                    { required: true, message: "请选择版本", trigger: "change" },
                ],
                className: [
                    { required: true, message: "请输入课时名称", trigger: "blur" },
                ],
                unitName: [
                    { required: true, message: "请输入单元名称", trigger: "blur" },
                ]
            },
            delFileList: [],
            classifyName: '',
            openVersionKeyShow: false,
        };
    },
    created() {
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.data) {
            this.form = JSON.parse(this.$route.query.data)
            this.classId = this.form.classId
            console.log(this.form)
            this.getfamousCourseInfo()
        } else {
        }
    },
    unmounted() {
    },
    computed: {
    },
    methods: {
        delFx(item) {
            var _this = this;
            ElMessageBox.confirm(
                '是否删除该版本?',
                '删除',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                deleteDict({ dicId: item.dicId }).then(res => {
                    if (res.data.code == 1) {
                        if (_this.form.versionKey == item.dictKey) {
                            _this.form.versionKey = ''
                        }
                        _this.$message.success('删除成功')
                        this.getQueryDict();
                    } else {
                        _this.$message.error('操作失败')
                    }
                })
            }).catch(() => {

            })
        },
        submiVersionKeyShowForm() {
            classLibraryAddClassify({ classifyName: this.classifyName }).then(res => {
                if (res.data.code == 1) {
                    this.getQueryDict();
                    this.$message.success('添加成功')
                    this.openVersionKeyShow = false;
                } else {
                    this.$message.error('添加失败')
                }
            })
        },
        openLeadClassify() {
            this.classifyName = ''
            this.openVersionKeyShow = true;
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.coverUrl != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getfamousCourseInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                // famousCourseInfo({ courseId: this.courseId }).then(res => {
                //     this.form = res.data.data;
                this.periodList.forEach(j => {
                    if (this.form.periodKey == j.dictKey) {
                        this.gradeList = j.childrenList;
                    }
                })
                this.periodList.forEach(e => {
                    if (this.form.periodKey == e.dictKey) {
                        e.childrenList.forEach(j => {
                            if (this.form.gradeKey == j.dictKey) {
                                this.subjectList = j.childrenList;
                            }
                        })
                    }
                })
                // })
            })

        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)
        },
        handleXAChange(file) {
            console.log(file);
        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'class_version' }).then(res => {
                this.versionKeyList = res.data.data;
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)

            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.classId) {
                        classLibraryUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/classLibrary', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        classLibraryCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/classLibrary', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            // this.jAFileList = [];
            this.replace('/classLibrary')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>