<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>群组设置</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!customId">新增群组</el-breadcrumb-item>
                <el-breadcrumb-item v-if="customId">编辑群组</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="客户" prop="customId">
                            <el-select clearable @change="changeCustom" v-model="form.customId" placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId" :label="item.customName"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="组织" prop="orgId">
                            <el-tree-select ref="tsd" default-expand-all node-key="orgId" clearable @change="changeOrg"
                                v-model="form.orgId" check-strictly :data="orgList" placeholder="请选择组织" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群名称" prop="qunName">
                            <el-input v-model="form.qunName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群主账号" prop="adminAccount">
                            <el-input v-model="form.adminAccount" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="云本配置" prop="bookIds">
                            <el-col class="custom">
                                <el-link type="primary" @click="openBook" :underline="false">资源配置</el-link>
                                <div>
                                    <div class="fl-warp" style="display: flex;">
                                        <div v-for="item in bookList" class="csbbb" :style="form.bookIds.indexOf(item.id) != -1?'':'display:none'">
                                            <el-icon @click="delBookId(item.id)" class="eicon" style="cursor: pointer;"><Delete /></el-icon>
                                            <img style="width: 100px;margin-top: 10px;"
                                                :src="'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/book/' + item.id + '/img/cover.png_original'"
                                                alt="" class="image">
                                            <div style="width: 100px;text-align: center;" class="text-one mb15">{{
                                                item.bookName }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <el-checkbox-group v-model="form.bookIds">
                                    <el-checkbox :label="item.id" v-for="item in bookList">
                                        <img style="width: 100px;margin-top: 10px;"
                                            :src="'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/book/' + item.id + '/img/cover.png_original'"
                                            alt="" class="image">
                                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                                    </el-checkbox>
                                </el-checkbox-group> -->
                            </el-col>

                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
        <el-dialog :title="'配置云本'" v-model="chooseBook" width="670px">
            <el-col class="custom ylsb" style="">
                <div v-for="item in bookList" :style="form.bookIds.indexOf(item.id) != -1?'display:none':''" >
                    <div class="ylqs" @click="setChooseBook(item.id)" :style="chooseBookIds.indexOf(item.id) != -1?'background: #a3d6ff':''">
                        <img style="width: 100px;margin-top: 10px;"
                            :src="'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com/book/' + item.id + '/img/cover.png_original'"
                            alt="" class="image">
                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                    </div>
                </div>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitChooseBookForm">确 定</el-button>
                    <el-button @click="chooseBookCancel">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun } from "@/api/adminservice/qun"

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            customId: '',
            groupData: {},
            id: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                adminName: '',
                adminUserId: '',
                orgId: ''
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
                orgId: [
                    { required: true, message: "请选择组织", trigger: "change" },
                ],
                qunName: [
                    { required: true, message: "请输入群名称", trigger: "blur" },
                ],
                adminAccount: [
                    { required: true, message: "请输入群主账号", trigger: "blur" },
                    { validator: this.validateUsername, trigger: "blur" }
                ],
                bookIds: [
                    { required: true, message: "请选择资源库书本", trigger: "change" },
                ],
            },
            bookList: [],
            configObj: {
                bookIds: [],
                orgId: ''
            },
            customList: [],
            orgList: [],
            oldBookIds: [],
            chooseBookIds:[],
            chooseBook: false,
        };
    },
    created() {

    },
    mounted() {
        if (this.$route.query.groupData) {
            this.customId = this.$route.query.customId;
            this.form.customId = this.$route.query.customId;
            this.getList();

            console.log(344, JSON.parse(this.$route.query.groupData))
            Object.assign(this.form, JSON.parse(this.$route.query.groupData))
            this.oldBookIds = JSON.parse(this.$route.query.groupData).bookIds;
            customOrgBook({ orgId: this.form.orgId }).then(res => {
                this.bookList = res.data.data;
            })

            queryTreeCustomOrg({ customId: this.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                this.orgList = res.data.data;
            })


        } else {
            this.customId = this.$route.query.customId;
            this.form.customId = this.$route.query.customId;
            queryTreeCustomOrg({ customId: this.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                this.orgList = res.data.data;
            })
            this.getList();
        }

    },
    computed: {
    },
    methods: {
        delBookId(id){
            this.form.bookIds.splice(this.form.bookIds.indexOf(id),1)
        },
        setChooseBook(id){
            if(this.chooseBookIds.indexOf(id) === -1){
                this.chooseBookIds.push(id)
            }else{
                this.chooseBookIds.splice(this.chooseBookIds.indexOf(id),1)
            }
        },
        chooseBookCancel(){
            this.chooseBook = false;
        },
        submitChooseBookForm(){
            this.form.bookIds = this.form.bookIds.concat(this.chooseBookIds)
            this.chooseBook = false;
        },
        openBook() {
            this.chooseBookIds = [];
            this.chooseBook = true;
        },
        changeOrg() {
            console.log(this.$refs['tsd'])
            console.log(this.oldBookIds);
            this.form.bookIds = [];
            console.log(this.form.orgId)
            customOrgBook({ orgId: this.form.orgId }).then(res => {
                this.bookList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldBookIds.forEach(j => {
                        if (e.id == j) {
                            this.form.bookIds.push(e.id)
                        }
                    })
                })
            })
        },
        validateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    this.form.adminName = res.data.data.nickName;
                    this.form.adminUserId = res.data.data.userId;

                    callback()
                }
            })
        },
        changeCustom() {
            this.orgList = [];
            this.form.orgId = '';
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
        },
        submitConfigForm() {
            console.log(this.configObj)
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.tableData.forEach(e => {
                        if (e.orgId) {
                            if (e.orgId == this.configObj.orgId) {
                                e.bookIds = this.configObj.bookIds
                            }
                        }
                    })
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        configurationRow(index, row) {
            if (row.adminAccount == '') {
                this.$message.error("请填写管理员账号")
                return;
            }
            if (row.orgName == '') {
                this.$message.error("请填写组织名称")
                return;
            }
            this.readBook = true;
            this.configObj.bookIds = row.bookIds;
            this.configObj.orgId = row.orgId;
            console.log(index, row)
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginDate === '' || !this.form.beginDate) {
                callback(new Error('请选择开始时间'))
            } else if (this.form.endDate === '' || !this.form.endDate) {
                callback(new Error('请选择结束时间'))
            } else {
                callback()
            }
        },
        startDateConfirm(e) {
            if (e > this.form.endDate) {
                this.form.endDate = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginDate) {
                this.form.beginDate = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date())
        },
        enddisabledDate(current) {
            if (this.form.beginDate) {
                return current && current < this.form.beginDate
            } else {
                return current && current < Number(new Date())
            }
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            // bookList().then(res => {
            //     this.bookList = res.data.data;
            // });
            queryCustoms({username:this.$storage.get('customId')?JSON.parse(this.$storage.get('userInfo')).username:''}).then(res => {
                this.customList = res.data.data;
            });
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.form.orgList = this.tableData;
                    console.log(this.form)
                    if (this.$route.query.groupData) {
                        qunUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                // this.$store.state.tagsView.visitedViews.forEach(e => {
                                //     if (e.path == '/groupSet') {
                                //         this.$store.dispatch('tagsView/delView', e).then(({
                                //             visitedViews
                                //         }) => {
                                //         })
                                //     }
                                // })
                                this.replace('/groupSet', { customId: this.customId,isSx:1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        qunCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                // this.$store.state.tagsView.visitedViews.forEach(e => {
                                //     if (e.path == '/groupSet') {
                                //         this.$store.dispatch('tagsView/delView', e).then(({
                                //             visitedViews
                                //         }) => {
                                //         })
                                //     }
                                // })
                                this.replace('/groupSet', { customId: this.customId,isSx:1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/groupSet', { customId: this.customId })
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {

                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList

                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}
.ylsb{
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}
.ylqs{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}
.eicon{
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}
.csbbb:hover .eicon{
    display: block;
}
</style>