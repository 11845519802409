<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>师资培训</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!trainId">新增培训任务</el-breadcrumb-item>
                <el-breadcrumb-item v-if="trainId">编辑听培训任务</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="任务标题" prop="title">
                            <el-input v-model="form.title" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="任务封面" prop="coverUrl">
                            <el-upload class="avatar-uploader"
                                action="https://www.jingzhunjy.com/businessservice/train/uploadCover" :show-file-list="false"
                                :on-success="handleAvatarSuccess" :headers="{ token }" :before-upload="beforeAvatarUpload">
                                <img v-if="form.coverUrl" :src="form.coverUrl" class="avatar" style="position:absolute" />
                                <el-icon class="avatar-uploader-icon" style="font-size:100px;color:#fff;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                            <div style="color: #a9abad;width:100%;">推荐使用540*304尺寸的图片</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="培训性质" prop="trainNature">
                            <el-select @change="changeTrainNature" style="width: 100%;" v-model="form.trainNature"
                                placeholder="请选择备课性质">
                                <el-option v-for="item in natureList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.trainNature != '1'">
                    <el-col :span="12">
                        <el-form-item :label="form.trainNature == 4 ? '群组' : '工作室'" prop="groupId">
                            <el-select @change="changeQun" style="width: 100%;" v-model="form.groupId"
                                placeholder="请选择工作室/群组">
                                <el-option v-for="item in groupList" :key="item.groupId" :label="item.groupName"
                                    :value="item.groupId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="类型" prop="type">
                            <el-radio-group v-model="form.type">
                                <el-radio :label="0">线上直播</el-radio>
                                <el-radio :label="1">线上录播</el-radio>
                                <el-radio :label="2">线下培训</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="任务时段" prop="formTime">
                            <el-col :span="11" class="date_picker">
                                <el-date-picker format="YYYY-MM-DD HH:mm" @change="startDateConfirm"
                                    :disabled-date="disabledDate" style="width: 100%;" v-model="form.beginTime"
                                    type="datetime" placeholder="开始时间" value-format="x" />
                            </el-col>
                            <el-col :span="2" class="text-center">
                                <span class="text-gray-500">至</span>
                            </el-col>
                            <el-col :span="11">
                                <el-date-picker format="YYYY-MM-DD HH:mm" class="date_picker" @change="endDateConfirm"
                                    :disabled-date="enddisabledFormDate" style="width: 100%;" v-model="form.endTime"
                                    type="datetime" placeholder="结束时间" value-format="x" />
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 2">
                    <el-col :span="12">
                        <el-form-item label="打卡次数" prop="signCount">
                            <el-radio-group v-model="form.signCount">
                                <el-radio :label="2">2</el-radio>
                                <el-radio :label="4">4</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 2">
                    <el-col :span="24">
                        <el-form-item label="签到时间" prop="signInTime">
                            <el-time-picker format="HH:mm" @change="startsignInConfirm" value-format="x"
                                v-model="form.signInBeginTime" placeholder="开始时间" />
                            <div style="padding: 0 15px 0 15px ;">至</div>
                            <el-time-picker format="HH:mm" @change="endsignInConfirm" value-format="x"
                                v-model="form.signInEndTime" placeholder="结束时间" />

                            <!-- <el-time-picker value-format="x" @change="changeSignInTime" v-model="form.signInTime" is-range
                                range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" /> -->

                            <!-- <el-col :span="11" class="date_picker">
                                <el-date-picker @change="startSignInDateConfirm" :disabled-date="disabledDate"
                                    style="width: 100%;" v-model="form.signInBeginTime" type="datetime" placeholder="开始时间"
                                    value-format="x" />
                            </el-col>
                            <el-col :span="2" class="text-center">
                                <span class="text-gray-500">至</span>
                            </el-col>
                            <el-col :span="11">
                                <el-date-picker class="date_picker" @change="endSignInDateConfirm"
                                    :disabled-date="enddisabledFormDate" style="width: 100%;" v-model="form.signInEndTime"
                                    type="datetime" placeholder="结束时间" value-format="x" />
                            </el-col> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 2">
                    <el-col :span="24">
                        <el-form-item label="签退时间" prop="signOutTime">
                            <el-time-picker format="HH:mm" @change="startsignOutConfirm" value-format="x"
                                v-model="form.signOutBeginTime" placeholder="开始时间" />
                            <div style="padding: 0 15px 0 15px ;">至</div>
                            <el-time-picker format="HH:mm" @change="endsignOutConfirm" value-format="x"
                                v-model="form.signOutEndTime" placeholder="结束时间" />

                            <!-- <el-time-picker value-format="x" @change="changeSignOutTime" v-model="form.signOutTime" is-range
                                range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" /> -->

                            <!-- <el-col :span="11" class="date_picker">
                                <el-date-picker @change="startSignOutDateConfirm" :disabled-date="disabledDate"
                                    style="width: 100%;" v-model="form.signOutBeginTime" type="datetime" placeholder="开始时间"
                                    value-format="x" />
                            </el-col>
                            <el-col :span="2" class="text-center">
                                <span class="text-gray-500">至</span>
                            </el-col>
                            <el-col :span="11">
                                <el-date-picker class="date_picker" @change="endSignOutDateConfirm"
                                    :disabled-date="enddisabledFormDate" style="width: 100%;" v-model="form.signOutEndTime"
                                    type="datetime" placeholder="结束时间" value-format="x" />
                            </el-col> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 2 && form.signCount == 4">
                    <el-col :span="24">
                        <el-form-item label="二次签到时间" prop="secondSignInTime">
                            <el-time-picker format="HH:mm" @change="startSecondsignInConfirm" value-format="X"
                                v-model="form.secondSignInBeginTime" placeholder="开始时间" />
                            <div style="padding: 0 15px 0 15px ;">至</div>
                            <el-time-picker format="HH:mm" @change="endSecondsignInConfirm" value-format="x"
                                v-model="form.secondSignInEndTime" placeholder="结束时间" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 2 && form.signCount == 4">
                    <el-col :span="24">
                        <el-form-item label="二次签退时间" prop="secondSignOutTime">
                            <el-time-picker format="HH:mm" @change="startSecondsignOutConfirm" value-format="x"
                                v-model="form.secondSignOutBeginTime" placeholder="开始时间" />
                            <div style="padding: 0 15px 0 15px ;">至</div>
                            <el-time-picker format="HH:mm" @change="endSecondsignOutConfirm" value-format="x"
                                v-model="form.secondSignOutEndTime" placeholder="结束时间" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 2">
                    <el-col :span="12">
                        <el-form-item label="签到总次数(次)" prop="signTotalCount">
                            <el-input-number v-model="form.signTotalCount" step-strictly :min="1" :max="100000" />
                            <span style="color: red;" class="ml15">*签到+签退为一次打卡</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 2">
                    <el-col :span="12">
                        <el-form-item label="签到地址" prop="signInAddress">
                            <el-input v-model="form.signInAddress" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="" v-show="form.type == 2">
                    <!-- <span>搜索框和地图试图都展示</span> -->
                    <aMap v-if="isShow" ref="zdyMap" @getGeocoder="getGeocoder" :oldCenter="oldCenter" :isShowAMap="true">
                    </aMap>

                    <!-- <span>只展示搜索框 不展示地图试图</span>
                    <aMap :toParentsMap="toParentsMap" :isShowAMap="true" :isDisplayAMap="false"></aMap> -->
                </el-form-item>
                <el-row v-if="form.type == 2">
                    <el-col :span="12">
                        <el-form-item label="签到范围(m)" prop="signInDistance">
                            <el-input-number v-model="form.signInDistance" step-strictly :min="1000" :max="100000" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="培训级别" prop="trainLevel">
                            <el-select style="width: 100%;" v-model="form.trainLevel" placeholder="请选择课题级别">
                                <el-option v-for="item in lessonLevelList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col :span="24">
                        <el-form-item label="参与人员" prop="joinUsersList">
                            <!-- <el-select style="width: 100%;" multiple filterable v-model="form.joinUsersList"
                                placeholder="请选择参与人员">
                            </el-select> -->
                            <choosePeople :showTitle="'选择人员'" ref="joinUsersList" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 1">
                    <el-col :span="12">
                        <el-form-item label="线上培训课程" prop="courseIds">
                            <el-select style="width: 100%;" v-model="form.courseIds" placeholder="请选择培训课程">
                                <el-option v-for="item in trainList" :key="item.courseId" :label="item.courseName"
                                    :value="item.courseId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 0">
                    <el-col :span="12">
                        <el-form-item label="线上培训地址" prop="linkUrl">
                            <!-- <el-tree-select
                                node-key="courseId" style="width: 100%;" v-model="form.linkUrl" :data="trainList"
                                :render-after-expand="false" default-expand-all /> -->
                            <el-input v-model="form.linkUrl" placeholder="最多256个字" maxlength="256" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="培训内容" prop="content">
                            <el-input type="textarea" v-model="form.content" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-col :span="12">
                    <el-form-item label="培训任务量表" prop="scaleIds">
                        <el-select style="width:100%" multiple filterable v-model="form.scaleList" placeholder="请选择培训任务量表">
                            <el-option v-for="item in rateScaleList" :key="item.scaleId" :label="item.title"
                                :value="item.scaleId">
                                <span style="float: left">{{ item.title }}</span>
                                <span @click.stop="preview(item)" style="float: right; color: #8492a6; font-size: 13px">
                                    <el-icon>
                                        <View />
                                    </el-icon></span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="上传附件" prop="">
                            <el-upload v-model:file-list="fileList" class="upload-demo" :on-remove="handleRemove"
                                action="https://www.jingzhunjy.com/businessservice/train/uploadFile"
                                :on-change="handleJAChange" :headers="{ token }" accept=".pdf,.ppt,.pptx,.doc,.docx"
                                :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div class="mb30" v-for="(item, index) in previewData.questionList">
                <div class="mb15" v-if="item.type != 3">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                    }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                            item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                                : ''
                        }}]</span><span v-if="item.type == 4 && item.totalScore"
                            style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                item.totalScore }})</span>
                        <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                            item.totalScore }})</span>
                    </h3>
                </div>
                <div class="mb15" v-if="item.type == 3">
                    <div class="mb15">
                        <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                        }}.</span>{{ item.questionName }}
                            <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                    '文字内容'
                                    : ''
                            }}]</span>
                            <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                                item.totalScore }})</span>
                        </h3>
                    </div>
                    <div>

                        <el-input-number size="small" step-strictly disabled :min="0" :max="100" />
                    </div>
                </div>
                <div v-if="item.type == 1">
                    <el-radio-group disabled class="ml-4">
                        <el-radio label="1" size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span></el-radio>
                    </el-radio-group>
                </div>
                <div v-if="item.type == 2">
                    <el-checkbox-group disabled class="ml-4">
                        <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="item.type == 4" style="width: 100%;">
                    <el-input style="width: 100%;" disabled :rows="2" type="textarea" placeholder="" />
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { trainListReleaseCourse } from "@/api/admin/traCourse"
import aMap from '@/components/aMap/index' //引入封装好的组件
import { lessonCreate, lessonUpdate, delLessonFile } from "@/api/admin/lesson"
import { queryDict, userList, groupListAuthGroup } from "@/api/admin/public";
import { lessonInfo } from "@/api/admin/lesson";
import { orgListTypeOrg, orgListSchoolOrg } from "@/api/admin/system";
import { rateScaleList, rateScaleInfo } from "@/api/admin/evaluation";
import { trainCreate, trainUpdate, trainInfo, trainDeleteFile } from "@/api/admin/train";

export default {
    name: "demoAdd",
    components: {
        aMap
    },
    data() {
        return {
            fileList: [],
            toParentsMap: {

            },
            isShow: false,
            oldCenter: [],
            trainId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            lessonLevelList: [
            ],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            groupList: [],
            natureList: [],
            // 表单参数
            form: {
                coverUrl: 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/szpx.png',
                type: 0,
                signCount: 2,
                title: '',
                trainNature: '1',
                joinUsersList: [],
                signInBeginTime: '',
                signInEndTime: '',
                signOutBeginTime: '',
                signOutEndTime: '',
                signInAddress: '',
                signInDistance: 1000,
                signTotalCount: 0,
                trainLevel: '',
                courseIds: '',
                linkUrl: '',
                beginTime: '',
                endTime: '',
                content: '',
                scaleIds: '',
                scaleList: [],
                groupId: '',
                secondSignInBeginTime: '',
                secondSignInEndTime: '',
                secondSignOutBeginTime: '',
                secondSignOutEndTime: ''
            },
            // 表单校验
            rules: {
                trainNature: [
                    { required: true, message: "请选择培训性质", trigger: "change" },
                ],
                groupId: [
                    { required: true, message: "请选择工作室/群组", trigger: "change" },
                ],
                coverUrl: [
                    { required: true, validator: this.checkCoverValidator }
                ],
                type: [
                    { required: true, message: "请选择类型", trigger: "change" },
                ],
                signCount: [
                    { required: true, message: "请选择打卡次数", trigger: "change" },
                ],
                signTotalCount: [
                    { required: true, message: "请选择打卡次数", trigger: "change" },
                ],
                courseIds: [
                    { required: true, message: "请选择培训课程", trigger: "change" },
                ],
                title: [
                    { required: true, message: "请输入任务标题", trigger: "blur" },
                ],
                joinUsersList: [
                    { required: true, validator: this.checkjoinUsersList },
                ],
                content: [
                    { required: true, message: "请输入培训内容", trigger: "blur" },
                ],
                trainLevel: [
                    { required: true, message: "请选择培训级别", trigger: "change" },
                ],
                linkUrl: [
                    { required: true, message: "请输入线上培训地址", trigger: "blur" },
                ],
                signInAddress:[
                    { required: true, message: "请输入签到地址", trigger: "blur" },
                ],
                signInDistance:[
                    { required: true, message: "请输入签到范围", trigger: "blur" },
                ],
                formTime: [
                    { required: true, validator: this.checkValidator }
                ],
                signInTime: [
                    { required: true, validator: this.checksignInTimeValidator }
                ],
                signOutTime: [
                    { required: true, validator: this.checksignOutTimeValidator }
                ],
                secondSignInTime: [
                    { required: true, validator: this.checksecondSignInTimeValidator }
                ],
                secondSignOutTime: [
                    { required: true, validator: this.checksecondSignOutTimeValidator }
                ]
            },
            delFileList: [],
            countyList: [],
            schoolList: [],
            trainList: [],
            rateScaleList: [],
            previewData: {},
            previewShow: false,
        };
    },
    created() {
        this.getRateScaleList();
        this.getTrainListCourse();
        this.getOrgListTypeOrg();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.trainId) {
            this.trainId = this.$route.query.trainId
            console.log(this.form)
            this.getlessonInfo()
        } else {
            this.isShow = true
        }
    },
    unmounted() {
        if (this.trainId) {
            try {
                console.log(332)
                this.fileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        trainDeleteFile({ fileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.fileList.forEach(e => {
                    trainDeleteFile({ fileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }

        }
    },
    computed: {
    },
    methods: {
        checkjoinUsersList(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.$refs.joinUsersList.checkList.length == 0) {
                callback(new Error('请选择参与人员'))
            } else {
                callback()
            }
        },
        preview(item) {
            console.log(item)
            rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                this.previewData = res.data.data;
                this.previewShow = true;
            })
        },
        getRateScaleList() {
            rateScaleList({ scaleType: 2 }).then(res => {
                this.rateScaleList = res.data.data;
            })
        },
        getTrainListCourse() {
            trainListReleaseCourse().then(res => {
                this.trainList = res.data.data;
                console.log(this.trainList)
            })
        },
        changeSignInTime(e) {
            console.log(this.form.signInTime);
            this.form.signInBeginTime = this.form.signInTime[0];
            this.form.signInEndTime = this.form.signInTime[1];
        },
        changeSignOutTime(e) {
            console.log(this.form.signOutTime);
            this.form.signOutBeginTime = this.form.signOutTime[0];
            this.form.signOutEndTime = this.form.signOutTime[1];
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.coverUrl != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        getGeocoder(e) {
            console.log(e);
            this.form.lat = e.center[1];
            this.form.lng = e.center[0];
            this.form.signInAddress = e.formattedAddress;
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        changeQun(e) {
            console.log(this.form.groupId);
            if (this.form.trainNature && this.form.trainNature == 4) {
                this.sxKj()
                this.$refs.joinUsersList.getQunPeople(this.form.groupId)
            }
        },
        disabledSeconds(hour, minute) {
            return this.makeRange(1, 59)
        },
        makeRange(start, end) {
            const result = []
            for (let i = start; i <= end; i++) {
                result.push(i)
            }
            return result
        },
        changeTrainNature(e) {
            this.groupList = [];
            this.form.groupId = '';
            if (e == 1) {
                this.sxKj()
                this.$refs.joinUsersList.getUserList()
            } else if (e == 4) {
                this.sxKj()
                groupListAuthGroup({ groupNature: 1 }).then(res => {
                    this.groupList = res.data.data;
                })
            }
        },
        sxKj() {
            this.$refs.joinUsersList.checkList = [];
        },
        // setHms(e) {
        //     if (e) {
        //         const date = new Date(e);
        //         date.setSeconds(0);
        //         return date.getTime();
        //     }else{
        //         return ''
        //     }
        // },
        getlessonInfo() {
            this.form.scaleList = [];
            trainInfo({ trainId: this.trainId }).then(res => {
                if (res.data.data.trainNature) {
                    this.changeTrainNature(res.data.data.trainNature)
                }

                this.form = res.data.data;
                if (!this.form.signCount) {
                    this.form.signCount = 2;
                }
                this.form.signInTime = [this.form.signInBeginTime, this.form.signInEndTime];
                this.form.signOutTime = [this.form.signOutBeginTime, this.form.signOutEndTime];
                this.form.trainLevel = this.form.trainLevel + ''
                this.$refs.joinUsersList.checkList = this.form.joinUserList;
                if (this.form.trainNature && this.form.trainNature == 4) {
                    this.$refs.joinUsersList.getQunPeople(this.form.groupId)
                }
                // this.form.joinUsersList = this.form.joinUsers.split(',')
                this.form.courseIds = '';
                this.form.scaleList = this.form.scaleIds && this.form.scaleIds.split(',')
                if (this.form.courseList && this.form.courseList.length != 0) {
                    this.form.courseIds = this.form.courseList[0].courseId;
                }
                if (this.form.type == 2) {
                    this.oldCenter = [this.form.lng, this.form.lat]
                }
                this.form.fileList.forEach(e => {
                    this.fileList.push({
                        name: e.fileName,
                        fileId: e.fileId,
                        url: e.fileUrl,
                        response: {
                            data: e.fileUrl
                        }
                    })
                })
                this.isShow = true;
                if (this.form.type == 2) {
                    setTimeout(() => {
                        this.$refs.zdyMap.center = this.oldCenter;
                        this.$refs.zdyMap.componentMarker.position = this.oldCenter;
                    }, 100)
                }

            })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || rawFile.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || rawFile.type == 'application/msword')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx,.doc,.docx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e >= this.form.endTime) {
                this.form.endTime = '';
            }
        },
        startsignInConfirm(e) {
            if (e >= this.form.signInEndTime) {
                this.form.signInEndTime = '';
            }
        },
        startSecondsignInConfirm(e) {
            console.log(e);
            if (e >= this.form.secondSignInEndTime) {
                this.form.secondSignInEndTime = '';
            }
        },
        startsignOutConfirm(e) {
            if (e >= this.form.signOutEndTime) {
                this.form.signOutEndTime = '';
            }
        },
        startSecondsignOutConfirm(e) {
            if (e >= this.form.secondSignOutEndTime) {
                this.form.secondSignOutEndTime = '';
            }
        },
        startSignInDateConfirm(e) {
            if (e >= this.form.signInEndTime) {
                this.form.signInEndTime = '';
            }
        },
        startSignOutDateConfirm(e) {
            if (e >= this.form.signOutEndTime) {
                this.form.signOutEndTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e <= this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        endsignOutConfirm(e) {
            if (e <= this.form.signOutBeginTime) {
                this.form.signOutBeginTime = '';
            }
        },
        endSecondsignOutConfirm(e) {
            if (e <= this.form.secondSignOutBeginTime) {
                this.form.secondSignOutBeginTime = '';
            }
        },
        endsignInConfirm(e) {
            if (e <= this.form.signInBeginTime) {
                this.form.signInBeginTime = '';
            }
        },
        endSecondsignInConfirm(e) {
            if (e <= this.form.secondSignInBeginTime) {
                this.form.secondSignInBeginTime = '';
            }
        },
        endSignInDateConfirm(e) {
            console.log(e,)
            if (e <= this.form.signInBeginTime) {
                this.form.signInBeginTime = '';
            }
        },
        endSignOutDateConfirm(e) {
            console.log(e,)
            if (e <= this.form.signOutBeginTime) {
                this.form.signOutBeginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        checksignInTimeValidator(rule, value, callback) {
            if (this.form.signInBeginTime && !this.form.signInEndTime) {
                callback(new Error('请选择结束时间'))
            } else if (this.form.signInEndTime && !this.form.signInBeginTime) {
                callback(new Error('请选择开始时间'))
            } else if (!this.form.signInEndTime && !this.form.signInBeginTime) {
                callback(new Error('请选择签到时间'))
            } else {
                callback()
            }
        },
        checksecondSignInTimeValidator(rule, value, callback){
            if (this.form.secondSignInBeginTime && !this.form.secondSignInEndTime) {
                callback(new Error('请选择结束时间'))
            } else if (this.form.secondSignInEndTime && !this.form.secondSignInBeginTime) {
                callback(new Error('请选择开始时间'))
            } else if (!this.form.secondSignInEndTime && !this.form.secondSignInBeginTime) {
                callback(new Error('请选择二次签到时间'))
            } else {
                callback()
            }
        },
        checksignOutTimeValidator(rule, value, callback) {
            if (this.form.signOutBeginTime && !this.form.signOutEndTime) {
                callback(new Error('请选择结束时间'))
            } else if (this.form.signOutEndTime && !this.form.signOutBeginTime) {
                callback(new Error('请选择开始时间'))
            } else if (!this.form.signInEndTime && !this.form.signInBeginTime) {
                callback(new Error('请选择签退时间'))
            } else {
                callback()
            }
        },
        checksecondSignOutTimeValidator(rule, value, callback){
            if (this.form.secondSignOutBeginTime && !this.form.secondSignOutEndTime) {
                callback(new Error('请选择结束时间'))
            } else if (this.form.secondSignOutEndTime && !this.form.secondSignOutBeginTime) {
                callback(new Error('请选择开始时间'))
            } else if (!this.form.secondSignOutEndTime && !this.form.secondSignOutBeginTime) {
                callback(new Error('请选择二次签到时间'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            orgListTypeOrg({ orgType: 2 }).then(res => {
                this.countyList = res.data.data;
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'topic_level' }).then(res => {
                this.lessonLevelList = res.data.data;
            })
            queryDict({ code: 'activity_nature' }).then(res => {
                this.natureList = res.data.data;
                this.natureList.splice(1, 2)
                // if (this.$storage.get("role") == 4 || this.$storage.get("role") == 5) {
                //     this.natureList.splice(0, 1)
                // }
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)

            this.form.beginTime = this.setHms(this.form.beginTime);
            this.form.endTime = this.setHms(this.form.endTime);

            this.form.signInBeginTime = this.setHms(this.form.signInBeginTime);
            this.form.signInEndTime = this.setHms(this.form.signInEndTime);
            this.form.signOutBeginTime = this.setHms(this.form.signOutBeginTime);
            this.form.signOutEndTime = this.setHms(this.form.signOutEndTime);

            this.form.secondSignInBeginTime = this.setHms(this.form.secondSignInBeginTime);
            this.form.secondSignInEndTime = this.setHms(this.form.secondSignInEndTime);
            this.form.secondSignOutBeginTime = this.setHms(this.form.secondSignOutBeginTime);
            this.form.secondSignOutEndTime = this.setHms(this.form.secondSignOutEndTime);

            console.log(this.form)
            this.form.joinUsersList = [];

            var fileList = [];
            this.fileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.$refs.joinUsersList.checkList.forEach(e => {
                this.form.joinUsersList.push(e.userId)
            })
            this.form.joinUsers = this.form.joinUsersList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    obj.fileUrls = fileList.toString();
                    obj.scaleIds = obj.scaleList.toString();
                    delete obj.joinUsersList

                    if (this.trainId) {
                        trainUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    trainDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("新增成功");
                                this.replace('/train', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        trainCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    trainDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("编辑成功");
                                this.replace('/train', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            // this.jAFileList = [];
            this.replace('/train')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>