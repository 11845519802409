<template>
    <div class="app-container">

        <el-card shadow="never" style="height: calc(100vh - 100px);overflow: auto;">
            <el-row>
                <el-col :span="12">
                    <el-form style="padding-bottom: 15px;" ref="form" :model="form" :rules="rules" label-width="60px">
                        <el-row>个人信息</el-row>
                        <div class="mt20" style="border: 1px solid #e3e3e3;padding:10px;border-radius: 10px;">
                            <el-row class="mt20">
                                <el-col :span="24">
                                    <el-form-item label="头像:" prop="bannerUrl">
                                        <el-upload class="avatar-uploader"
                                            action="https://www.jingzhunjy.com/businessservice/appHome/userCenter/avatar"
                                            :show-file-list="false" :on-success="handleAvatarSuccess" :headers="{ token }"
                                            :before-upload="beforeAvatarUpload">
                                            <img v-if="form.avatar" :src="form.avatar + '?_=' + Date.now()" class="avatar"
                                                style="position:absolute" />
                                            <el-icon class="avatar-uploader-icon" style="font-size:100px;color:#fff;">
                                                <Plus />
                                            </el-icon>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="姓名:" prop="trueName">
                                        <el-input v-model="form.trueName" placeholder="最多16个字" maxlength="16" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="性别:" prop="gender">
                                        <el-radio-group v-model="form.gender">
                                            <el-radio :label="0">男</el-radio>
                                            <el-radio :label="1">女</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="身份:" prop="roleName">
                                        {{ form.roleName }}
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="学段" prop="periodKey">
                                        <el-select @change="changePeriod" v-model="form.periodKey" placeholder="请选择学段">
                                            <el-option v-for="item in periodList" :key="item.dictKey"
                                                :label="item.dictValue" :value="item.dictKey" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="年级" prop="gradeKey">
                                        <el-select @change="changeGrade" v-model="form.gradeKey" placeholder="请选择年级">
                                            <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                                :value="item.dictKey" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="学科" prop="subjectKeyList">
                                        <el-select multiple style="width: 100%;" v-model="form.subjectKeyList"
                                            placeholder="请选择学科">
                                            <el-option v-for="item in subjectList" :key="item.dictKey"
                                                :label="item.dictValue" :value="item.dictKey" />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="职称:" prop="teacherRankName">
                                        {{ form.teacherRankName }}
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="归属:" prop="orgName">
                                        {{ form.orgName }}
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="手机号:" prop="username">
                                        {{ form.username }}
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div style="width: 100%;display:flex;">
                                <el-button style="margin: 0 auto;" type="primary" @click="submitForm">保 存</el-button>
                            </div>
                        </div>
                    </el-form>
                </el-col>
                <el-col :span="12">
                    <el-row class="ml15">
                        <el-col :span="24">荣誉成就</el-col>
                        <el-col :span="24" class="mt10" style="border: 1px solid #e3e3e3;padding:10px;border-radius: 10px;">
                            <div>
                                <img v-for="item in form.honorList" style="width: 100px;padding: 20px;"
                                    :src="require(`../../assets/rycj/ry-${item}.png`)" alt="">
                            </div>
                            <el-empty style="margin: 0 auto;" v-if="form.honorList.length == 0" description="暂无数据" />
                        </el-col>
                    </el-row>
                    <el-row class="ml15 mt20">
                        <el-col :span="24">荣誉证书</el-col>
                        <el-row class="mt10"
                            style="border: 1px solid #e3e3e3;padding:10px;border-radius: 10px;width: 100%;">
                            <div v-for="(item, index) in certList">
                                <el-image :initial-index="index" fit="cover" :preview-src-list="certList"
                                    style="width: 180px;height:130px;margin-right:10px;margin-bottom: 10px;object-fit: cover;"
                                    :src="item" alt="" />
                            </div>
                            <el-empty style="margin: 0 auto;" v-if="certList.length == 0" description="暂无数据" />
                        </el-row>
                    </el-row>
                </el-col>

            </el-row>
        </el-card>
        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
    </div>
</template>

<script>
import { userCenterInfo, userCenterUpdate, queryDict, authserviceSchoolList, userCerts } from "@/api/admin/public";
import { bannerCreate, bannerUpdate } from "@/api/admin/news";
import { orgListTree } from "@/api/admin/system";
import TEditor from '@/components/TEditor.vue';

export default {
    name: "demoAdd",
    components: {
        TEditor
    },
    data() {
        return {
            orgList: [],
            treeData: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                title: '',
                content: '',
                appContent: '',
                bannerUrl: '',
                type: 0,
                avatar: '',
                gender: 0,
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                subjectKeyList: [],
                honorList: []
            },
            periodList: [],
            gradeList: [],
            subjectList: [],
            showFileList: [],
            certList: [],
            // 表单校验
            rules: {
            },
        };
    },
    created() {
        this.getUserCerts();
        this.getOrgListTypeOrg();
        this.getUserCenterInfo();
    },
    mounted() {

    },
    computed: {
    },
    methods: {
        getUserCerts() {
            this.certList = []
            userCerts().then(reson => {
                reson.data.data.forEach(e => {
                    this.certList.push(e.certUrl)
                })
            })
        },
        getOrgListTypeOrg() {
            orgListTree().then(res => {
                this.treeData = res.data.data;
            })
            authserviceSchoolList().then(res => {
                this.orgList = res.data.data;
                console.log(res);
            })
        },
        deWeightTwo() {
            var temp = [];
            this.subjectList.forEach(function (a) {
                var check = temp.every(function (b) {
                    return a.dictKey !== b.dictKey;
                })
                check ? temp.push(a) : ''
            })
            return temp;
        },
        getUserCenterInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                userCenterInfo().then(res => {
                    this.form = res.data.data;
                    if (this.form.honor) {
                        this.form.honorList = this.form.honor.split(',')
                        if (this.form.honorList[this.form.honorList.length - 1] == '') {
                            this.form.honorList.splice(this.form.honorList.length - 1, 1)
                        }
                    } else {
                        this.form.honorList = [];
                    }
                    console.log(this.form.honorList);
                    if (this.form.subjectKey) {
                        this.form.subjectKeyList = this.form.subjectKey.split(',')
                    }
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey) {
                                    if (this.form.gradeKey == j.dictKey) {
                                        this.subjectList = j.childrenList;
                                    }
                                } else {
                                    j.childrenList.forEach(m => {
                                        this.subjectList.push(m)
                                    })
                                }
                            })
                        }
                    })
                    this.$storage.set("userInfo", JSON.stringify(res.data.data));
                    this.$emit('changeInfo', res.data.data)
                    if (!this.form.avatar || this.form.avatar == '') {
                        if (this.form.gender) {
                            if (this.form.gender == 0) {
                                this.form.avatar = 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/nan.png'
                            } else {
                                this.form.avatar = 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/nv.png'
                            }
                        }
                    }
                    console.log(res);
                    this.subjectList = this.deWeightTwo();
                })
            })
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.bannerUrl != '') {
                callback()
            } else {
                callback(new Error('请上传轮播图图片'))
            }
        },
        checkContentValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.content != '') {
                callback()
            } else {
                callback(new Error('请填写内容'))
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.avatar = null;
                this.form.avatar = uploadFile.response.data;
                this.$emit('changeImg', uploadFile.response.data)
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            if (this.form.subjectKeyList.length != 0) {
                this.form.subjectKey = this.form.subjectKeyList.toString();
            }

            userCenterUpdate(this.form).then(res => {
                if (res.data.code == 1) {

                    this.$message.success('保存成功')
                    this.getUserCenterInfo();
                } else {
                    this.$message.error('保存失败')
                }
            })
        },

        // 取消按钮
        cancel() {
            this.replace('/banner')
            // this.$emit('isTg', true)
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey) {
                            if (this.form.gradeKey == j.dictKey) {
                                this.subjectList = j.childrenList;
                            }
                        } else {
                            j.childrenList.forEach(m => {
                                this.subjectList.push(m)
                            })
                        }
                    })
                }
            })
            this.subjectList = this.deWeightTwo();
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>